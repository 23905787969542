import React, { useEffect, useMemo, useState } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
} from "@fluentui/react-components";
import CollectionPageFilterSort from "./CollectionPageFilterSort";
import CollectionPageComponent from "./CollectionPageComponent";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import qs from "qs";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../hooks/useFirebase";
import { Helmet } from "react-helmet-async";

const fetchCollectionInfo = async (identifier) => {
  // Create a reference to the document
  const docRef = doc(db, "Collections", identifier);

  // Fetch the document
  const docSnap = await getDoc(docRef);

  // Check if the document exists
  if (docSnap.exists()) {
    // Return the document data
    return docSnap.data();
  } else {
    // Handle the case where the document does not exist
    console.log("No such document!");
    return null;
  }
};

const fetchCreatorInfo = async (identifier, user) => {
  const userId = user?.uid;
  if (identifier === userId) {
    const newObj = JSON.parse(JSON.stringify(user));
    newObj.displayName = newObj.displayName || "You";
    return newObj;
  }

  const url = `https://us-central1-thingiverse-explore.cloudfunctions.net/getUser?identifier=${identifier}`;

  const resp = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await resp.json();
};

const fetchNumItems = async (identifier) => {
  const numItems = await getDocs(
    query(
      collection(db, "CollectionThings"),
      where("collectionId", "==", identifier)
    )
  ).then((snapshot) => {
    return snapshot.docs.length;
  });
  return numItems;
};

const fetchCollectionTags = async (identifier) => {
  // Add orderBy clause for sorting by tagName in ascending order
  const q = query(
    collection(db, "CollectionTagJoinTable"),
    where("collectionId", "==", identifier)
  );
  const querySnapshot = await getDocs(q);
  const collectionTags = querySnapshot.docs.map((doc) => ({
    uid: doc.id,
    ...doc.data(),
  }));
  return collectionTags;
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    height: "100%",
    overflowY: "scroll",
    alignItems: "center",
    boxSizing: "border-box",
  },
  crunchWrapSupreme: {
    display: "flex",
    ...shorthands.flex(1),
    flexDirection: "column",
    width: "100%",
    maxWidth: "1200px",
    boxSizing: "border-box",
  },
  mainBody: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.flex(1),
    alignItems: "flex-start",
    boxSizing: "border-box",
    justifyContent: "flex-start",
    "@media (max-width: 850px)": {
      flexDirection: "column",
    },
  },
});

function CollectionPage() {
  const styles = useStyles();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [sort, setSort] = React.useState("newest");
  const [cursor, setCursor] = React.useState(null);
  const user = useSelector((state) => state.user.value);
  const collectionId = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true })?.id,
    [location.search]
  );

  const [normalCollection, setNormalCollection] = useState(
    location?.state?.normalCollection
  );

  const fetchNormalCollection = React.useCallback(async () => {
    const promises = [
      fetchCollectionInfo(collectionId),
      fetchNumItems(collectionId),
      fetchCollectionTags(collectionId),
    ];

    const [collectionInfo, numItems, collectionTags] = await Promise.all(
      promises
    );

    const creatorInfo = await fetchCreatorInfo(collectionInfo.userId, user);
    setNormalCollection({
      ...collectionInfo,
      numItems,
      creatorInfo,
      collectionTags,
      uid: collectionId,
    });
  }, [collectionId, user]);

  const canonicalUrl = useMemo(() => {
    const baseUrl = window.location.pathname;
    return baseUrl + (location.search ? location.search : "");
  }, [location]);

  const props = useMemo(() => {
    return {
      page,
      setPage,
      cursor,
      setCursor,
      sort,
      setSort,
      normalCollection,
      fetchNormalCollection,
    };
  }, [page, cursor, sort, normalCollection, fetchNormalCollection]);

  React.useEffect(() => {
    if (!normalCollection) {
      fetchNormalCollection();
    }
  }, [fetchNormalCollection, normalCollection]);

  useEffect(() => {
    fetchNormalCollection();
  }, [fetchNormalCollection]);

  return (
    <>
      <Helmet>
        <title>3D GO - 3D Model Collection</title>
        <meta
          name="description"
          content={`View 3D Models in ${
            normalCollection?.title ?? "This Collection"
          }`}
        />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div
        className={styles.root}
        style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
        id="modelsPageRef"
      >
        <div className={styles.crunchWrapSupreme}>
          <div className={styles.mainBody}>
            <CollectionPageFilterSort {...props} />
            <CollectionPageComponent {...props} />
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(CollectionPage);
