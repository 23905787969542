import React, { useCallback, useMemo, useState } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
} from "@fluentui/react-components";
import ProfilePageFilterSort from "./ProfilePageFilterSort";
import ProfilePageComponent from "./ProfilePageComponent";
import { useLocation } from "react-router-dom";
import qs from "qs";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    height: "100%",
    overflowY: "scroll",
    alignItems: "center",
    boxSizing: "border-box",
  },
  crunchWrapSupreme: {
    display: "flex",
    ...shorthands.flex(1),
    flexDirection: "column",
    width: "100%",
    maxWidth: "1200px",
    boxSizing: "border-box",
  },
  mainBody: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.flex(1),
    alignItems: "flex-start",
    boxSizing: "border-box",
    justifyContent: "flex-start",
    "@media (max-width: 850px)": {
      flexDirection: "column",
    },
  },
});

function ProfilePage() {
  const styles = useStyles();
  const location = useLocation();
  const user = useSelector((state) => state.user.value);
  const [sort, setSort] = React.useState("newest");
  const profileId = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true })?.id,
    [location.search]
  );

  const [normalProfile, setNormalProfile] = useState(
    location?.state?.normalProfile
  );

  const canonicalUrl = useMemo(() => {
    const baseUrl = window.location.pathname;
    return baseUrl + (location.search ? location.search : "");
  }, [location]);

  const fetchNormalProfile = useCallback(async () => {
    const userId = user.uid;
    if (!user || !userId || !profileId) return;
    if (profileId === userId) {
      const newObj = JSON.parse(JSON.stringify(user));
      newObj.displayName = newObj.displayName || "You";
      return newObj;
    }

    const url = `https://us-central1-thingiverse-explore.cloudfunctions.net/getUser?identifier=${profileId}`;

    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await resp.json();
    setNormalProfile(result);
  }, [profileId, user]);

  React.useEffect(() => {
    if (!normalProfile) {
      fetchNormalProfile();
    }
  }, [fetchNormalProfile, normalProfile]);

  const props = useMemo(() => {
    return {
      sort,
      setSort,
      normalProfile,
    };
  }, [normalProfile, sort]);

  return (
    <>
      <Helmet>
        <title>3D GO Profile Page</title>
        <meta
          name="description"
          content="View 3D GO User Profile Details. View Public 3D Model Collections."
        />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div
        className={styles.root}
        style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
        id="modelsPageRef"
      >
        <div className={styles.crunchWrapSupreme}>
          <div className={styles.mainBody}>
            <ProfilePageFilterSort {...props} />
            <ProfilePageComponent {...props} />
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(ProfilePage);
