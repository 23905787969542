import { StlViewer } from "react-stl-viewer";
import { useEffect, useRef, useState } from "react";
import BarLoader from "react-spinners/BarLoader";
import { HexColorPicker } from "react-colorful";
import { teamsDarkTheme } from "@fluentui/react-components";
import demoSTL from "../../demo.stl";

function Previewer() {
  const style = {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "black",
  };

  const modelRef = useRef(null);
  const [modelColor, setModelColor] = useState(
    teamsDarkTheme.colorBrandStroke1
  );
  const [loading, setLoading] = useState(true);
  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <div>
      {loading && (
        <div
          className="loaderWrap"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <BarLoader color="white" loading size={100} />
        </div>
      )}
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          margin: "20px",
          zIndex: 100,
        }}
      >
        {showPicker ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <HexColorPicker
              color={modelColor}
              onChange={setModelColor}
              style={{ maxWidth: "100px", maxHeight: "100px" }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px",
                cursor: "pointer",
              }}
              onClick={() => setShowPicker(false)}
            >
              close
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "45px",
              height: "20px",
              backgroundColor: modelColor,
              borderRadius: 7,
              border: "solid lightgrey 2px",
              boxSizing: "border-box",
            }}
            onClick={() => setShowPicker(true)}
          />
        )}
      </div>
      <StlViewer
        style={style}
        orbitControls={true}
        shadows={true}
        modelProps={{
          ref: modelRef,
          color: modelColor,
          scale: 1.25,
          positionX: 250,
          positionY: 250,
          rotationX: 0,
          rotationY: 0,
          rotationZ: 0,
        }}
        showAxes={true}
        floorProps={{ gridLength: 500, gridWidth: 500 }}
        onFinishLoading={() => setLoading(false)}
        url={demoSTL}
      />
    </div>
  );
}

export default Previewer;
