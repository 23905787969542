import React, { useCallback, useEffect, useMemo } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Text,
  Divider,
  SkeletonItem,
  Image,
} from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import qs from "qs";
import createApiAgent from "../../utils/api-agent";
import AgentIcon from "../../Components/AgentIcon/AgentIcon";
import moment from "moment";
import { MdCalendarMonth } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import { isMobile } from "react-device-detect";

// const proxyUrl =
//   "http://127.0.0.1:5001/thingiverse-explore/us-central1/proxyImage?agent=printables&url="; //DEV
const proxyUrl =
  "https://us-central1-thingiverse-explore.cloudfunctions.net/proxyImage?agent=printables&url=";

const getPrintablesContestPreview = (contest) => {
  const baseUrl = "https://media.printables.com/";
  try {
    if (contest.teaserImageFilePath) {
      const pathParts = contest.teaserImageFilePath.split("/");
      let fileType = pathParts[pathParts.length - 1].split(".").pop();
      pathParts.splice(-1, 0, `thumbs/cover/640x480/${fileType}`);
      return proxyUrl + baseUrl + pathParts.join("/");
    } else {
      return "";
    }
  } catch (e) {
    console.log(e);
    return proxyUrl + baseUrl + contest.teaserImageFilePath;
  }
};

const normalizeContest = (contest) => {
  const newObj = {};
  newObj.agent = contest.blendSource;
  if (contest.blendSource === "printables") {
    newObj.name = contest.name;
    newObj.description = contest.description;
    newObj.imageUrl = getPrintablesContestPreview(contest);
    newObj.fallbackImageUrl =
      proxyUrl + "https://media.printables.com/" + contest.teaserImageFilePath;
    newObj.publicUrl = `https://printables.com/contest/${contest.id}-${contest.slug}`;
    newObj.compId = contest.id;
    newObj.endTime = contest.openTo;
    newObj.numEntered = contest.printsCount;
  } else if (contest.blendSource === "makerworld") {
    newObj.name = contest.contestName;
    newObj.description = contest.contestDesc;
    newObj.imageUrl =
      contest.cover + "?image_process=resize,w_1200/format,webp";
    newObj.fallbackImageUrl =
      contest.cover + "?image_process=resize,w_1200/format,webp";
    newObj.publicUrl = `https://makerworld.com/en/contests/${contest.id}`;
    newObj.compId = contest.id;
    newObj.endTime = contest.endTime;
    newObj.numEntered = contest.contestantsCount;
  }
  return newObj;
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    ...shorthands.padding("20px"),
    ...shorthands.gap("15px"),
    boxSizing: "border-box",
    flexGrow: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    alignItems: "center",
  },
  resultsWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    ...shorthands.gap("5px"),
    ...shorthands.flex(1),
  },
  openClosedH: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    ...shorthands.gap("5px"),
  },
  dot: {
    width: "15px",
    height: "15px",
    ...shorthands.borderRadius("100%"),
  },
  contestWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    // ...shorthands.gap("5px"),
    ...shorthands.flex(1),
    justifyContent: "center",
    flexShrink: 1,
  },
  cardWrap: {
    position: "relative",
    display: "flex",
    boxShadow: teamsDarkTheme.shadow2,
    width: "100%",
    height: "100%",
    minHeight: "300px", // Ensures a minimum height
    minWidth: "300px", // Sets the minimum width as required
    cursor: "pointer",
    ...shorthands.flex("1", "1", "auto"), // Allows the item to grow and shrink but not smaller than its content
    ...shorthands.borderRadius("5px"), // Keeps your original border-radius
    ...shorthands.border("1px", "solid", "transparent"),
    border: "1px solid transparent",
    boxSizing: "border-box",
    ":hover": {
      backgroundColor: teamsDarkTheme.colorNeutralBackground3Hover,
      ...shorthands.border("1px", "solid", teamsDarkTheme.colorBrandStroke1),
    },
    "@media (max-width: 850px)": {
      maxWidth: "100%", // Ensures it takes full width on smaller screens, adjust as needed
    },
  },
  skeletonItem: {
    display: "flex",
    ...shorthands.flex(1),
    width: "100%",
    height: "100%",
  },
  contestImage: {
    position: "absolute",
    display: "flex",
    width: "100%",
    height: "100%",
    ...shorthands.borderRadius("5px"),
  },
  contestContent: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-sart",
    justifyContent: "flex-end",
  },
  contestText: {
    backgroundColor: "rgba(0,0,0,0.7)",
    boxSizing: "border-box",
    ...shorthands.padding("5px"),
    ...shorthands.borderRadius("5px"),
    fontWeight: "bold",
    position: "absolute",
    bottom: "50%",
    left: "10%",
    width: "80%",
    textAlign: "center",
  },
});

const ContestCard = ({ contest, loading, providers }) => {
  const styles = useStyles();
  const navigate = useNavigate();

  const competitionObj = useMemo(() => normalizeContest(contest), [contest]);

  const [imgSource, setImgSource] = React.useState(
    competitionObj ? competitionObj?.imageUrl : null
  );

  useEffect(() => {
    setImgSource(competitionObj ? competitionObj?.imageUrl : null);
  }, [competitionObj]);

  const handleClick = () => {
    navigate(`/contests/contest?${qs.stringify({ ...competitionObj })}`);
  };

  const onError = React.useCallback(() => {
    if (imgSource === competitionObj.fallbackImageUrl && imgSource) {
      return;
    } else {
      setImgSource(competitionObj.fallbackImageUrl);
    }
  }, [imgSource, competitionObj]);

  if (loading) {
    return (
      <div className={styles.cardWrap}>
        <div
          style={{
            display: "flex",
            flex: 1,
          }}
        >
          <SkeletonItem
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
      </div>
    );
  }

  if (!providers[competitionObj?.agent]) {
    return null;
  }

  return (
    <div className={styles.cardWrap} onClick={handleClick}>
      <Image
        src={imgSource}
        className={styles.contestImage}
        style={{
          backgroundColor: teamsDarkTheme.colorNeutralBackground2,
        }}
        alt="contest"
        fit="cover"
        onError={onError}
      />
      <Text className={styles.contestText}>{competitionObj.name}</Text>
      <div className={styles.contestContent}>
        <div
          style={{
            // position: "absolute",
            // bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            padding: "5px",
            boxSizing: "border-box",
            backgroundColor: "rgba(0,0,0,0.7)",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <AgentIcon agent={competitionObj.agent} />
            <Text>{competitionObj.numEntered} Entries</Text>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <MdCalendarMonth size={16} />
            <Text bold>{moment(competitionObj.endTime).format("LL")}</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

function ContestsComponent({ providers }) {
  const styles = useStyles();
  const navigate = useNavigate();
  const apiAgent = useMemo(() => createApiAgent("blend"), []);
  const [loading, setLoading] = React.useState(true);

  const [contests, setContests] = React.useState({
    open: new Array(3).fill({}),
    closed: new Array(9).fill({}),
  });

  const loadContests = useCallback(async () => {
    setLoading(true);
    const loadedContests = await apiAgent.getContests({
      providers: {
        printables: true,
        makerworld: true,
      },
    });
    setContests(loadedContests);
    setLoading(false);
  }, [apiAgent]);

  React.useEffect(() => {
    loadContests();
  }, [loadContests, providers]);

  return (
    <div
      className={styles.root}
      style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "5px",
          backgroundColor: teamsDarkTheme.colorNeutralBackground1,
          padding: "10px",
          borderRadius: "5px",
          border: `1px solid ${teamsDarkTheme.colorNeutralStroke1}`,
        }}
      >
        <Text
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
          weight="semibold"
          size={isMobile ? 600 : 800}
        >
          <FaTrophy />
          Recent 3D Model Contests
        </Text>
      </div>
      <div className={styles.openClosedH}>
        <div
          className={styles.dot}
          style={{
            backgroundColor: "green",
          }}
        />
        <Text size={500} weight="bold">
          Open
        </Text>
      </div>
      <Divider
        style={{
          flex: 0,
        }}
      />
      <div className={styles.contestWrap}>
        {contests.open.map((data, i) => {
          return (
            <div
              key={i}
              style={{
                flex: 1,
                boxSizing: "border-box",
              }}
            >
              <ContestCard
                contest={data}
                loading={loading}
                providers={providers}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.openClosedH}>
        <div
          className={styles.dot}
          style={{
            backgroundColor: "red",
          }}
        />
        <Text size={500} weight="bold">
          Closed
        </Text>
      </div>
      <Divider
        style={{
          flex: 0,
        }}
      />
      <div className={styles.contestWrap}>
        {contests.closed.map((data, i) => {
          return (
            <div
              key={i}
              style={{
                flex: 1,
                boxSizing: "border-box",
              }}
            >
              <ContestCard
                contest={data}
                loading={loading}
                providers={providers}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default React.memo(ContestsComponent);
