import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
  mergeClasses,
  Toaster,
  useId,
  useToastController,
} from "@fluentui/react-components";
import { useMotion } from "@fluentui/react-motion-preview";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowPaywall } from "../../store/items/user";
import { useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import PaywallTab from "./Components/PaywallTab";
import Lottie from "lottie-react";
import bg from "./Components/dark-bg.json";
import { CloseSharp } from "@mui/icons-material";
import CheckoutTab from "./CheckoutTab";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../hooks/useFirebase";

const useStyles = makeStyles({
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100dvh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 150,
    backgroundColor: teamsDarkTheme.colorBackgroundOverlay,
    opacity: 0,
    transitionDuration: "150ms",
    transitionTimingFunction: "ease-out",
    boxSizing: "border-box",
    // overflowY: "auto",
  },
  visibleOverlay: {
    opacity: 1,
  },
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    ...shorthands.borderRadius("5px"),
    boxSizing: "border-box",
    backgroundColor: teamsDarkTheme.colorNeutralBackground3,
    maxWidth: "400px",
    // minHeight: "450px",
    transform: "translate3D(0, 0, 0) scale(0.75)",
    transitionDuration: "150ms",
    transitionTimingFunction: "ease-out",
    position: "relative",
    boxShadow: teamsDarkTheme.shadow2,
    overflowY: "auto",
    "@media (max-width: 400px)": {
      minWidth: "100vw",
      width: "100%",
      minHeight: "100dvh",
      maxHeight: "auto",
      overflowY: "auto",
    },
    "@media (max-height: 450px)": {
      minWidth: "100vw",
      width: "100%",
      minHeight: "100dvh",
      maxHeight: "auto",
      overflowY: "auto",
    },
    maxHeight: "100dvh",
    // maxHeight: "700px",
  },
  visibleMain: {
    transform: "translate3D(0, 0, 0) scale(1)",
  },
  swiper: {
    width: "100%",
    height: "100%",
  },
  swipeWrap: {
    width: "100%",
    height: "100%",
    display: "flex",
    ...shorthands.flex(1),
    ...shorthands.padding("20px"),
    boxSizing: "border-box",
    // maxHeight: "100vh",
    zIndex: 100,
  },
  closer: {
    position: "absolute",
    top: "5px",
    right: "5px",
    cursor: "pointer",
    zIndex: 100,
    // backgroundColor: teamsDarkTheme.colorNeutralBackground1,
    ...shorthands.borderRadius("5px"),
    // ...shorthands.border("1px", "solid", teamsDarkTheme.colorNeutralStroke1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const Onboarding = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const swiperRef = React.useRef(null);
  const showModal = useSelector((state) => state.user.showPaywall);
  const user = useSelector((state) => state.user.value);
  const revenueCat = useSelector((state) => state.user.revenueCat);
  const [searchParams, setSearchParams] = useSearchParams();
  const [swiperIndex, setSwiperIndex] = useState(0);

  const handleClose = useCallback(() => {
    dispatch(setShowPaywall(false));
    setSwiperIndex(0);
    localStorage.setItem("seen_onboarding", "true");
    if (searchParams.has("show_onboarding")) {
      searchParams.delete("show_onboarding");
      setSearchParams(searchParams);
    }
    if (searchParams.has("onboarding_slide")) {
      searchParams.delete("onboarding_slide");
      setSearchParams(searchParams);
    }
  }, [dispatch, searchParams, setSearchParams]);

  const showOnboarding = useMemo(() => {
    const value = searchParams.has("show_onboarding") || showModal;
    if (value) logEvent(analytics, "onboarding_paywall_shown");
    return value;
  }, [searchParams, showModal]);

  useEffect(() => {
    const slide = searchParams.get("onboarding_slide");
    if (slide && swiperRef?.current?.swiper) {
      swiperRef?.current?.swiper.slideTo(slide);
    }
  }, [searchParams, swiperRef]);

  const motion = useMotion(showOnboarding);

  const tabProps = useMemo(() => {
    return {
      swiperRef,
      handleClose,
      swiperIndex,
      dispatchToast,
    };
  }, [handleClose, swiperIndex, swiperRef, dispatchToast]);

  const tabs = useMemo(() => {
    const tabs = [];
    tabs.push({ title: "Paywall", content: <PaywallTab {...tabProps} /> });
    tabs.push({ title: "Paywall", content: <CheckoutTab {...tabProps} /> });
    return tabs;
  }, [tabProps]);

  useEffect(() => {
    if (revenueCat?.entitlements?.pro && showOnboarding) {
      handleClose();
    }
  }, [handleClose, revenueCat, showOnboarding]);

  return (
    <>
      {motion.canRender && (
        <div
          ref={motion.ref}
          className={mergeClasses(
            styles.overlay,
            motion.active && styles.visibleOverlay
          )}
        >
          <div
            ref={motion.ref}
            className={mergeClasses(
              styles.main,
              motion.active && styles.visibleMain
            )}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                overflow: "hidden",
              }}
            >
              <Lottie
                animationData={bg}
                style={{
                  // flex: 1,
                  minHeight: "450px",
                  overflow: "hidden",
                  opacity: 0.7,
                }}
                resizeMode="stretch"
                width={"100%"}
              />
            </div>
            <div className={styles.swipeWrap}>
              <Swiper
                autoplay={false}
                allowTouchMove={false}
                className={styles.swiper}
                spaceBetween={30}
                onActiveIndexChange={(e) => setSwiperIndex(e.activeIndex)}
                loop={false}
                ref={swiperRef}
              >
                {tabs.map((tab, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      {tab.content}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className={styles.closer} onClick={handleClose}>
              <CloseSharp
                style={{ width: "15px", height: "15px", opacity: 0.7 }}
              />
            </div>
          </div>
        </div>
      )}
      <Toaster toasterId={toasterId} />
    </>
  );
};

export default React.memo(Onboarding);
