import React, { useEffect, useMemo, useState } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
} from "@fluentui/react-components";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import FilterSort from "./FilterSort";
import ModelsComponent from "./ModelsComponent";
import { Helmet } from "react-helmet-async";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    height: "100%",
    overflowY: "scroll",
    alignItems: "center",
    boxSizing: "border-box",
  },
  crunchWrapSupreme: {
    display: "flex",
    ...shorthands.flex(1),
    flexDirection: "column",
    width: "100%",
    maxWidth: "1200px",
    boxSizing: "border-box",
  },
  mainBody: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.flex(1),
    alignItems: "flex-start",
    boxSizing: "border-box",
    justifyContent: "flex-start",
    "@media (max-width: 850px)": {
      flexDirection: "column",
    },
  },
});

function ModelsPage() {
  const styles = useStyles();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );

  const urlPage = useMemo(() => {
    const val = queryParams.get("page")
      ? parseInt(queryParams.get("page"))
      : undefined;
    return val;
  }, [queryParams]);
  const urlSort = useMemo(() => queryParams.get("sort"), [queryParams]);
  const urlProviders = useMemo(
    () => JSON.parse(queryParams.get("providers")),
    [queryParams]
  );
  const urlCategory = useMemo(() => queryParams.get("category"), [queryParams]);

  const navigate = useNavigate();
  const [page, setPage] = useState(urlPage ?? 1);
  const [sort, setSort] = React.useState(urlSort ?? "popular");
  const [providers, setProviders] = React.useState(
    urlProviders ?? {
      thingiverse: true,
      printables: true,
      makerworld: true,
      cults3d: true,
      myminifactory: false,
    }
  );

  const [category, setCategory] = React.useState(
    urlCategory ? urlCategory : "all"
  );

  const canonicalUrl = useMemo(() => {
    const baseUrl = window.location.pathname;
    return baseUrl + (location.search ? location.search : "");
  }, [location]);

  const props = useMemo(() => {
    return {
      page,
      setPage,
      sort,
      setSort,
      category,
      setCategory,
      providers,
      setProviders,
    };
  }, [category, page, providers, sort]);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(window.location.search);
    const initProviders = {
      thingiverse: true,
      printables: true,
      makerworld: true,
      cults3d: true,
      myminifactory: false,
    };
    if (sort !== "popular") {
      newSearchParams.set("sort", sort);
    } else {
      newSearchParams.delete("sort");
    }
    if (page !== 1) {
      newSearchParams.set("page", page.toString());
    } else {
      newSearchParams.delete("page");
    }
    if (category !== "all") {
      newSearchParams.set("category", category);
    } else {
      newSearchParams.delete("category");
    }
    if (JSON.stringify(providers) !== JSON.stringify(initProviders)) {
      newSearchParams.set("providers", JSON.stringify(providers));
    } else {
      newSearchParams.delete("providers");
    }

    setSearchParams(newSearchParams);
  }, [sort, page, category, providers, setSearchParams]);

  return (
    <>
      <Helmet>
        <title>3D Printer Files</title>
        <meta
          name="description"
          content={`3D Printer Files - Search Models - Model Details - 3D Previewing, Collect & Save.`}
        />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div
        className={styles.root}
        style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
        id="modelsPageRef"
      >
        <div className={styles.crunchWrapSupreme}>
          <div className={styles.mainBody}>
            <FilterSort {...props} />
            <ModelsComponent {...props} />
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(ModelsPage);
