import React, { useCallback, useEffect, useState } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Text,
  Divider,
  Button,
  Input,
} from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import CollectionCard from "../../Components/CollectionCard";
import { setShowCreateCollectionModal } from "../../store/items/things";
import { Close } from "@mui/icons-material";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    ...shorthands.padding("20px"),
    ...shorthands.gap("20px"),
    boxSizing: "border-box",
    flexGrow: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    alignItems: "center",
  },
  resultsWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    ...shorthands.gap("5px"),
    ...shorthands.flex(1),
  },
});

const createDateObj = (val) => {
  let date = new Date(val?.created_at);
  if (isNaN(date.getTime()) && val?.created_at?.seconds) {
    date = new Date(val.created_at.seconds * 1000); // Convert seconds to milliseconds
  }
  if (isNaN(date.getTime())) {
    date = new Date();
  }
  return date;
};

const sortAndFilter = (collections, order, searchText) => {
  if (!collections || !collections.length) return [];
  let newArr = JSON.parse(JSON.stringify(collections));

  if (searchText && searchText.length) {
    newArr = newArr.filter((collection) => {
      return collection.title.toLowerCase().includes(searchText.toLowerCase());
    });
  }

  if (order === "newest") {
    return newArr.sort((a, b) => {
      return createDateObj(b) - createDateObj(a);
    });
  } else if (order === "oldest") {
    return newArr.sort((a, b) => {
      return createDateObj(a) - createDateObj(b);
    });
  } else if (order === "aZ") {
    return newArr.sort((a, b) => {
      return a.title.localeCompare(b.title);
    });
  } else if (order === "Za") {
    return newArr.sort((a, b) => {
      return b.title.localeCompare(a.title);
    });
  }

  return newArr;
};

function CollectionsComponent({ sort }) {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userCollections = useSelector((state) => state.things.collections);

  const [searchText, setSearchText] = useState("");

  const [displayThings, setDisplayThings] = useState(
    sortAndFilter(userCollections, sort)
  );

  const handleCreateCollection = useCallback(() => {
    dispatch(setShowCreateCollectionModal(true));
  }, [dispatch]);

  const handleClosePress = React.useCallback(() => {
    setSearchText("");
  }, []);

  useEffect(() => {
    setDisplayThings(sortAndFilter(userCollections, sort, searchText));
  }, [sort, userCollections, searchText]);

  return (
    <div
      className={styles.root}
      style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
    >
      <div
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          display: "flex",
        }}
      >
        <Text weight="bold" size={600}>
          My Collections
        </Text>
        <Button
          appearance="primary"
          icon={<MdAdd />}
          onClick={handleCreateCollection}
        >
          Create Collection
        </Button>
      </div>
      <Divider
        style={{
          flex: 0,
        }}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <Input
          style={{
            color: "white",
            fontSize: "24px",
            width: "100%",
            backgroundColor: teamsDarkTheme.colorNeutralBackground2,
            ...shorthands.padding("10px", "20px"),
            ...shorthands.border(
              "1px",
              "solid",
              teamsDarkTheme.colorNeutralStroke1
            ),
            boxSizing: "border-box",
            ...shorthands.borderRadius("5px"),
          }}
          value={searchText}
          contentBefore={
            <FaSearch
              size={24}
              style={{ marginRight: "5px", padding: "5px 0px" }}
            />
          }
          contentAfter={
            searchText.length ? (
              <Button
                appearance="transparent"
                size="medium"
                icon={<Close />}
                onClick={handleClosePress}
              />
            ) : null
          }
          size="large"
          onChange={(e, data) => setSearchText(data.value)}
          appearance="underline"
          placeholder="Filter Collections..."
        />
      </div>
      <div className={styles.resultsWrap}>
        {displayThings && displayThings.length ? (
          <>
            {displayThings.map((c, i) => {
              return (
                <div key={i}>
                  <CollectionCard collection={c} />
                </div>
              );
            })}
          </>
        ) : (
          "No Collections... yet"
        )}
      </div>
    </div>
  );
}

export default React.memo(CollectionsComponent);
