import {
  Button,
  Spinner,
  Text,
  Title2,
  Title3,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { useEffect, useRef, useState } from "react";
import BarLoader from "react-spinners/BarLoader";
import { HexColorPicker } from "react-colorful";
import { useSearchParams } from "react-router-dom";
import "./MobileShapE.css";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
  },
  loaderWrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    ...shorthands.gap("10px"),
  },
  resultsMain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    ...shorthands.gap("10px"),
    ...shorthands.padding("20px"),
    height: "100%",
  },
  text: {
    textAlign: "center",
    // color: "black",
    // backgroundColor: "white",
    ...shorthands.borderRadius("5px"),
    ...shorthands.padding("5px", "10px"),
  },
  resultsUpper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // ...shorthands.gap("0px"),
    ...shorthands.borderRadius("5px"),
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "flex-end",
    ...shorthands.gap("10px"),
    flexGrow: 1,
  },
  footerLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.gap("10px"),
  },
  footerRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: "100%",
    ...shorthands.gap("10px"),
  },
  emptyWrap: {
    ...shorthands.padding("20px"),
    display: "flex",
    height: "100%",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
    ...shorthands.gap("20px"),
  },
  examplesWrap: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    ...shorthands.gap("10px"),
    ...shorthands.borderRadius("5px"),
    ...shorthands.padding("5px", "10px"),
  },
  clickables: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    ...shorthands.gap("10px"),
  },
});

function MobileShapE() {
  const client = window.gradioClient;
  const [prompt, setPrompt] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [showColors, setShowColors] = useState(false);
  const [color, setColor] = useState("transparent");
  const styles = useStyles();
  const modelRef = useRef(null);
  const [searchParams] = useSearchParams();
  const theme = searchParams.get("theme");
  const colors = {
    light: {
      primary: "#1d1d1f",
      textPrimary: "#000000",
      background: "rgb(232,232,237)",
      secondary: "#fafafc",
      border: "lightgrey",
      textSecondary: "#fafafc",
    },
    dark: {
      primary: "#5b5fc7",
      textPrimary: "#FFFFFF",
      textSecondary: "#FFFFFF",
      background: "#292929",
      secondary: "#3d3d3d",
      border: "transparent",
    },
  };
  const colorSet = colors[theme] || colors.light;

  const test = async (val) => {
    setLoading(true);
    const app = await client("https://hysts-shap-e.hf.space/--replicas/2lwpl/");
    const result = await app.predict("/load_example", [
      val, // undefined (index of selected example) in 'parameter_17' Dataset component
    ]);
    setPrompt(result.data[0]);
    setResponseData(result.data[1]);
    setLoading(false);
  };

  const newPrompt = async (val) => {
    setLoading(true);
    const app = await client("https://hysts-shap-e.hf.space/--replicas/2lwpl/");
    const randomNumber = Math.floor(Math.random() * 2147483648);
    const result = await app.predict("/text-to-3d", [
      val, // string  in 'Prompt' Textbox component
      randomNumber, // number (numeric value between 0 and 2147483647) in 'Seed' Slider component
      20, // number (numeric value between 1 and 20) in 'Guidance scale' Slider component
      100, // number (numeric value between 2 and 100) in 'Number of inference steps' Slider component
    ]);
    setPrompt(val);
    setResponseData(result.data[0]);
    setLoading(false);
  };

  useEffect(() => {
    const handleMessageFromWebView = (event) => {
      try {
        JSON.parse(event.data);
      } catch (err) {
        return;
      }
      // setResponseData(msg.data);
      const msg = JSON.parse(event.data);
      if (msg.type === "test") {
        //TODO run test function here
        test(Number(msg.value));
      } else if (msg.type === "prompt") {
        //TODO run prompt function here
        console.log(msg.value);
        newPrompt(msg.value);
      } else if (msg.type === "image") {
        //TODO run image function here
      }
    };

    window.addEventListener("message", handleMessageFromWebView);

    return () => {
      window.removeEventListener("message", handleMessageFromWebView);
    };
  }, []);

  const handleColorToggle = () => {
    setShowColors(!showColors);
  };

  const handleDownload = async () => {
    setDownloading(true);
    // const base64Zip = await createBase64(responseData.url);
    // window.ReactNativeWebView.postMessage(JSON.stringify({ file: base64Zip }));
    const url = "https://hysts-shap-e.hf.space/file=" + responseData.path;
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        file: url,
        name: prompt,
      })
    );
    setDownloading(false);
  };

  return (
    <div>
      <header
        className={styles.main}
        style={{ backgroundColor: colorSet.background }}
      >
        {loading ? (
          <div
            className={styles.loaderWrap}
            style={{ backgroundColor: colorSet.background }}
          >
            <BarLoader color={colorSet.textPrimary} loading size={100} />
            <Text style={{ color: colorSet.textPrimary }}>
              (this should take 10-15 seconds)
            </Text>
          </div>
        ) : responseData ? (
          <div className={styles.resultsMain}>
            <Title2
              className={styles.text}
              truncate={true}
              style={{
                maxHeight: 80,
                color: colorSet.textPrimary,
                backgroundColor: colorSet.secondary,
                border: `1px solid ${colorSet.border}`,
                overflow: "hidden",
              }}
            >
              {prompt}
            </Title2>
            <div
              className={styles.resultsUpper}
              style={{
                backgroundColor: colorSet.secondary,
                border: `1px solid ${colorSet.border}`,
              }}
            >
              <Text
                className={styles.text}
                style={{ color: colorSet.textPrimary }}
              >
                Renders are very rough. I am actively looking for better
                solutions as they become available.
              </Text>
              <Text
                className={styles.text}
                style={{ color: colorSet.textPrimary }}
              >
                For now, have some fun with the AI 😊
              </Text>
            </div>
            <model-viewer
              camera-controls
              alt="A 3D model of an astronaut"
              src={"https://hysts-shap-e.hf.space/file=" + responseData.path}
              ref={modelRef}
              style={{
                width: "225px",
                height: "225px",
                backgroundColor: color,
                border: `1px solid lightgrey`,
                borderRadius: "10px",
              }}
            >
              {/* <BarLoader
                color={colorSet.textPrimary}
                loading
                size={100}
                slot="progress-bar"
              /> */}
            </model-viewer>
            <div className={styles.footer}>
              <div className={styles.footerLeft}>
                {showColors ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <HexColorPicker
                      color={color}
                      onChange={setColor}
                      style={{ width: "125px", height: "125px" }}
                    />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                      }}
                      onClick={handleColorToggle}
                    >
                      <Text
                        style={{
                          color: colorSet.textPrimary,
                        }}
                      >
                        Close
                      </Text>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    onClick={handleColorToggle}
                  >
                    <div
                      style={{
                        width: "35px",
                        height: "15px",
                        backgroundColor: color,
                        border: `2px solid lightgrey`,
                        borderRadius: "10px",
                      }}
                    />
                    <Text
                      style={{
                        color: colorSet.textPrimary,
                        // backgroundColor: colorSet.secondary,
                        // border: `1px solid ${colorSet.border}`,
                        borderRadius: "5px",
                        padding: "5px 10px",
                      }}
                    >
                      Change Background
                    </Text>
                  </div>
                )}
              </div>
              <div className={styles.footerRight}>
                <Button
                  style={{ backgroundColor: colorSet.primary }}
                  onClick={() => handleDownload()}
                  icon={
                    downloading ? (
                      <Spinner
                        size="extra-tiny"
                        appearance={theme === "dark" ? "primary" : "inverted"}
                      />
                    ) : null
                  }
                >
                  Download
                </Button>
                <Button
                  className={styles.clearBtn}
                  onClick={() => {
                    setColor("transparent");
                    setResponseData(null);
                  }}
                  style={{
                    backgroundColor: colorSet.primary,
                    color: colorSet.textSecondary,
                  }}
                >
                  Clear Results
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.emptyWrap}>
            <Title3
              className={styles.text}
              style={{
                maxHeight: 80,
                color: colorSet.textPrimary,
                backgroundColor: colorSet.secondary,
                border: `1px solid ${colorSet.border}`,
              }}
            >
              AI Powered Text-to-3D (beta)
            </Title3>
            <Text
              className={styles.text}
              style={{
                color: colorSet.textPrimary,
                backgroundColor: colorSet.secondary,
                border: `1px solid ${colorSet.border}`,
              }}
            >
              Enter a prompt to generate a 3D model 🪄
            </Text>
            <div
              className={styles.examplesWrap}
              style={{
                backgroundColor: colorSet.secondary,
                border: `1px solid ${colorSet.border}`,
              }}
            >
              <Text style={{ color: colorSet.textPrimary }}>
                Try some examples:
              </Text>
              <div className={styles.clickables}>
                <Text
                  className={styles.text}
                  style={{
                    color: colorSet.textSecondary,
                    border: `1px solid ${colorSet.border}`,
                    backgroundColor: colorSet.primary,
                  }}
                  onClick={() => test(0)}
                >
                  A chair that looks like an avocado
                </Text>
                <Text
                  className={styles.text}
                  style={{
                    color: colorSet.textSecondary,
                    border: `1px solid ${colorSet.border}`,
                    backgroundColor: colorSet.primary,
                  }}
                  onClick={() => test(1)}
                >
                  An airplane that looks like a banana
                </Text>
                <Text
                  className={styles.text}
                  style={{
                    color: colorSet.textSecondary,
                    border: `1px solid ${colorSet.border}`,
                    backgroundColor: colorSet.primary,
                  }}
                  onClick={() => test(2)}
                >
                  A spaceship
                </Text>
                <Text
                  className={styles.text}
                  style={{
                    color: colorSet.textSecondary,
                    border: `1px solid ${colorSet.border}`,
                    backgroundColor: colorSet.primary,
                  }}
                  onClick={() => test(3)}
                >
                  A birthday cupcake
                </Text>
              </div>
            </div>
          </div>
        )}
      </header>
    </div>
  );
}

export default MobileShapE;
