import React, { useCallback, useMemo } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  Title3,
  shorthands,
  Divider,
} from "@fluentui/react-components";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import createApiAgent from "../../utils/api-agent";
import { useNavigate } from "react-router-dom";
import { modelNormalizer } from "../../utils/normalizers";
import ThingCard from "../../Components/ThingCard/ThingCard";
import qs from "qs";

const useStyles = makeStyles({
  relatedModelsWrap: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    ...shorthands.padding("10px", "0px"),
    ...shorthands.gap("10px"),
  },
  modelScroller: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    // overflowX: "scroll",
    ...shorthands.gap("5px"),
  },
  emptyHolder: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.padding("10px", "0px"),
    ...shorthands.borderRadius("5px"),
    backgroundColor: teamsDarkTheme.colorNeutralBackground3,
  },
});

const createFallbackTag = (name) => {
  const nameArr = name.split(" ");
  return nameArr[0];
};

function RelatedModels({ params, thing }) {
  const styles = useStyles();
  const navigate = useNavigate();
  const agent = thing.goFinal_agent;
  const apiAgent = useMemo(() => createApiAgent("blend"), []);
  const [isLoading, setIsLoading] = React.useState(true);
  const [relatedModels, setRelatedModels] = React.useState(
    new Array(10).fill(0)
  );

  const strings = {
    header: "Related Models",
    empty: "No Related Models",
  };

  const fetchRelatedModels = useCallback(async () => {
    const params = {
      sort: "popular",
      providers: {
        thingiverse: true,
        printables: true,
        makerworld: true,
        cults3d: false,
        myminifactory: false,
      },
      page: 1,
      limit: 8,
    };

    let finalStr = Array.isArray(thing.goFinal_tags)
      ? thing.goFinal_tags[0]
      : createFallbackTag(thing.goFinal_name);
    if (!finalStr) {
      finalStr = createFallbackTag(thing.goFinal_name);
    }

    const finalParams = { ...params, category: finalStr };

    setIsLoading(true);
    const res = await apiAgent.getModelsByCategory(finalParams);
    setRelatedModels(res);
    setIsLoading(false);
  }, [apiAgent, thing]);

  const handleClick = React.useCallback(
    (thing) => {
      const params = qs.stringify({
        agent: thing.goFinal_agent,
        id: thing.goFinal_shareId,
      });
      navigate(`/models/model?${params}`, { state: { thing } });
    },
    [navigate]
  );

  const methods = useMemo(() => {
    return {
      setSelectedThing: handleClick,
    };
  }, [handleClick]);

  React.useEffect(() => {
    fetchRelatedModels();
  }, [fetchRelatedModels]);

  return (
    <div className={styles.relatedModelsWrap}>
      <Title3>{strings.header}</Title3>
      <Divider />
      <div className={styles.modelScroller}>
        {relatedModels.length ? (
          relatedModels.map((model, i) => {
            const normalThing =
              model !== 0 && modelNormalizer(model, model.blendSource);
            return (
              <ThingCard
                thing={normalThing}
                agent={model.blendSource}
                loading={isLoading}
                methods={methods}
                isRelated={true}
                key={i}
              />
            );
          })
        ) : (
          <div className={styles.emptyHolder}>{strings.empty}</div>
        )}
      </div>
    </div>
  );
}

export default React.memo(RelatedModels);
