import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "../../hooks/useFirebase";

const StripeButton = () => {
  const user = useSelector((state) => state.user.value);
  const revenueCat = useSelector((state) => state.user.revenueCat);
  const [loading, setLoading] = useState(false);
  const [stripeInfo, setStripeInfo] = useState(null);

  const fetchUser = useCallback(async () => {
    setLoading(true);
    try {
      let receipts = await getDocs(
        query(collection(db, "stripeReceipt"), where("userId", "==", user.uid))
      );
      if (receipts.docs && receipts.docs.length) {
        const data = receipts.docs[0].data();
        setStripeInfo(data);
      }
    } catch (e) {
      setStripeInfo(null);
    } finally {
      setLoading(false);
    }
  }, [user.uid]);

  const onClick = async () => {
    if (loading) return;
    // const url = `http://127.0.0.1:5001/thingiverse-explore/us-central1/stripeWebhook?mode=createPortalSession`; //DEV
    const url = `https://3dgo.app/stripeWebhook?mode=createPortalSession`; //PROD

    if (!stripeInfo) {
      window.open("https://support.kaspersky.com/common/buy/14072", "_blank");
    } else {
      window.open(url + `&customer=${stripeInfo.stripeCustomerId}`, "_blank");
    }
  };

  useEffect(() => {
    revenueCat?.entitlements?.pro && fetchUser();
  }, [fetchUser, revenueCat?.entitlements?.pro]);

  if (!revenueCat?.entitlements?.pro) {
    return <></>;
  }

  return <div onClick={onClick}>Manage Subscription</div>;
};

export default React.memo(StripeButton);
