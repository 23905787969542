import React, { Suspense, useEffect, useRef, useState } from "react";
import { Canvas, useLoader, useThree } from "@react-three/fiber";
import { Html, OrbitControls } from "@react-three/drei";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { ThreeMFLoader } from "three/examples/jsm/loaders/3MFLoader.js";
import { BarLoader } from "react-spinners";

function Loader() {
  return (
    <Html center>
      <BarLoader color="white" loading size={100} />
    </Html>
  );
}

const Model = (props) => {
  const { url, baseColor } = props;
  const fileType = url.split(".").pop().toLowerCase();
  const loader =
    fileType === "stl"
      ? STLLoader
      : fileType === "obj"
      ? OBJLoader
      : ThreeMFLoader;
  const geom = useLoader(loader, url);

  const ref = useRef();
  const { camera } = useThree();

  useEffect(() => {
    camera.lookAt(ref.current.position);
  }, [camera, url]);

  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!geom || !url) return;
    try {
      geom.computeBoundingBox();

      const { min, max } = geom.boundingBox;

      // Center the model
      const offsetX = (min.x + max.x) / 2;
      const offsetY = (min.y + max.y) / 2;
      const offsetZ = (min.z + max.z) / 2;

      geom.translate(-offsetX, -offsetY, -offsetZ);

      const height = max.y - min.y;
      setHeight(height / 2);
    } catch (e) {
      console.log(e);
    }
  }, [geom, url]);

  return (
    <mesh ref={ref} position={[0, height, 0]}>
      <primitive object={geom} attach="geometry" />
      <meshStandardMaterial color={baseColor} />
    </mesh>
  );
};

export default function ThreeViewer(props) {
  return (
    <div
      className="content-div"
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <Canvas
        camera={{ position: [0, 300, 300] }}
        style={{
          width: "100%",
          height: "100%",
          background: "black",
        }}
      >
        <Suspense fallback={<Loader />}>
          <group>
            <Model {...props} />
            <gridHelper args={[500, 20]} />
          </group>
          <ambientLight />
          <directionalLight position={[0, 10, 0]} intensity={1} />
        </Suspense>
        <OrbitControls />
      </Canvas>
    </div>
  );
}
