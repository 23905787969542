import { createSlice } from "@reduxjs/toolkit";
//IAP / purchase info
export const thingsSlice = createSlice({
  name: "things",
  initialState: {
    showAddToCollectionModal: null,
    showCreateCollectionModal: null,
    collections: [],
    collectionThings: [],
  },
  reducers: {
    setShowAddToCollectionModal: (state, action) => {
      state.showAddToCollectionModal = action.payload;
    },
    setShowCreateCollectionModal: (state, action) => {
      state.showCreateCollectionModal = action.payload;
    },
    setCollections: (state, action) => {
      state.collections = action.payload;
    },
    setCollectionThings: (state, action) => {
      state.collectionThings = action.payload;
    },
    removeCollectionThing: (state, action) => {
      state.collectionThings = state.collectionThings.filter(
        (like) => like.uid !== action.payload.uid
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setShowAddToCollectionModal,
  setShowCreateCollectionModal,
  setCollections,
  setCollectionThings,
  removeCollectionThing,
} = thingsSlice.actions; // TODO

export default thingsSlice.reducer;
