import "./MarketingPage.css";
import Lottie from "lottie-react";
import cube from "./cube.json";
import { useEffect, useRef } from "react";

function MarketingPage() {
  const lottieRef = useRef(null);

  const lottieStyle = {
    position: "absolute",
    top: 0,
    right: 0,
    width: "100vw",
    height: "100vh",
    padding: 0,
    margin: 0,
  };

  useEffect(() => {
    lottieRef.current.setSpeed(0.5);
  }, []);
  return (
    <div className="MarketingPage">
      <Lottie animationData={cube} lottieRef={lottieRef} style={lottieStyle} />
      <h2 className="screenText">Awesome Things Incoming</h2>
    </div>
  );
}

export default MarketingPage;
