import React, { useCallback, useMemo } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  Checkbox,
  RadioGroup,
  Radio,
  Text,
  Divider,
} from "@fluentui/react-components";
import { useWindowSize } from "@uidotdev/usehooks";
import Lottie from "lottie-react";
import AgentIcon from "../../Components/AgentIcon/AgentIcon";
import cube from "../../Components/logo-lottie1.json";
import { FaSortAmountDown } from "react-icons/fa";
import { Sheet } from "react-modal-sheet";

const SORT_OPTIONS = {
  newest: {
    label: "Newest",
  },
  oldest: {
    label: "Oldest",
  },
  aZ: {
    label: "A → Z",
  },
  zA: {
    label: "Z → A",
  },
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    height: "100%",
    ...shorthands.flex(1),
    maxWidth: "250px",
    boxSizing: "border-box",
    ...shorthands.borderRight(
      "1px",
      "solid",
      teamsDarkTheme.colorNeutralStroke2
    ),
    ...shorthands.borderLeft(
      "1px",
      "solid",
      teamsDarkTheme.colorNeutralStroke2
    ),
    alignItems: "flex-start",
    "@media (max-width: 850px)": {
      //   maxWidth: "100%",
      ...shorthands.flex(0),
      maxWidth: "100%",
      width: "100%",
      ...shorthands.borderRight("none"),
      ...shorthands.borderLeft("none"),
      ...shorthands.borderBottom(
        "1px",
        "solid",
        teamsDarkTheme.colorNeutralStroke2
      ),
    },
  },
  mainDrawer: {
    display: "flex",
    flexDirection: "column",
    position: "sticky",
    top: "0px",
    ...shorthands.flex(1),
    ...shorthands.gap("10px"),
    ...shorthands.padding("20px", "0px"),
  },
  brandWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...shorthands.padding("10px", "0px"),
    ...shorthands.flex(1),
  },
  agentWrap: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("5px"),
  },
  brandText: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  optionsWrap: {
    display: "flex",
    flexDirection: "column",
  },
  accordian: {
    backgroundColor: "red",
  },
  agentH: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  catH: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap("5px"),
  },
  accordionBody: {
    backgroundColor: teamsDarkTheme.colorNeutralBackground3,
    ...shorthands.padding("10px"),
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("5px"),
    ...shorthands.borderRadius("5px"),
    boxShadow: `0px 0px 2px 0px ${teamsDarkTheme.colorNeutralStroke1}`,
  },
  mobileDrawer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    ...shorthands.flex(1),
    ...shorthands.padding("10px"),
    backgroundColor: teamsDarkTheme.colorNeutralBackground3,
  },
  mobileOptionsWrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    ...shorthands.gap("10px"),
    ...shorthands.padding("10px", "15px"),
    ...shorthands.borderRadius("15px"),
    ...shorthands.border("1px", "solid", teamsDarkTheme.colorNeutralStroke2),
    backgroundColor: teamsDarkTheme.colorNeutralBackground1,
    "@media (max-width: 400px)": {
      justifyContent: "center",
    },
  },
  mobileLeft: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap("5px"),
  },
  mobileAgentH: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

function NewSavedFilterSort({ sort, setSort, providers, setProviders }) {
  const styles = useStyles();
  const windowSize = useWindowSize();
  const [showModal, setShowModal] = React.useState(false);
  const lottieRef = React.useRef(null);

  const agentIconStyles = {
    width: "30px",
    height: "30px",
    borderRadius: "7px",
  };

  const lottieStyle = {
    width: `40px`,
    height: "40px",
    marginRight: "10px",
    borderRadius: "5px",
  };

  const handleUpdateProviders = useCallback(
    (val, agent) => {
      setProviders((prev) => {
        return { ...prev, [agent]: val };
      });
    },
    [setProviders]
  );

  const AccordionComponent = useMemo(() => {
    return (
      <Accordion
        collapsible
        multiple
        defaultOpenItems={["1", "2"]}
        style={{ color: "white" }}
      >
        <AccordionItem value="1">
          <AccordionHeader>Websites</AccordionHeader>
          <AccordionPanel className={styles.accordionBody}>
            <div className={styles.agentH}>
              <AgentIcon
                agent="thingiverse"
                styles={{ width: "25px", borderRadius: "10px" }}
              />
              <Checkbox
                checked={providers.thingiverse}
                onChange={(ev, data) =>
                  handleUpdateProviders(data.checked, "thingiverse")
                }
                label="Thingiverse"
              />
            </div>
            <div className={styles.agentH}>
              <AgentIcon
                agent="printables"
                styles={{ width: "25px", borderRadius: "10px" }}
              />
              <Checkbox
                checked={providers.printables}
                onChange={(ev, data) =>
                  handleUpdateProviders(data.checked, "printables")
                }
                label="Printables"
              />
            </div>
            <div className={styles.agentH}>
              <AgentIcon
                agent="cults3d"
                styles={{ width: "25px", borderRadius: "10px" }}
              />
              <Checkbox
                checked={providers.cults3d}
                onChange={(ev, data) =>
                  handleUpdateProviders(data.checked, "cults3d")
                }
                label="Cults 3D"
              />
            </div>
            <div className={styles.agentH}>
              <AgentIcon
                agent="makerworld"
                styles={{ width: "25px", borderRadius: "10px" }}
              />
              <Checkbox
                checked={providers.makerworld}
                onChange={(ev, data) =>
                  handleUpdateProviders(data.checked, "makerworld")
                }
                label="Makerworld"
              />
            </div>
            <div className={styles.agentH}>
              <AgentIcon
                agent="myminifactory"
                styles={{ width: "25px", borderRadius: "10px" }}
              />
              <Checkbox
                checked={providers.myminifactory}
                onChange={(ev, data) =>
                  handleUpdateProviders(data.checked, "myminifactory")
                }
                label="My Mini Factory"
              />
            </div>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem value="2">
          <AccordionHeader>Sorting</AccordionHeader>
          <AccordionPanel className={styles.accordionBody}>
            <RadioGroup
              value={sort}
              onChange={(_, data) => setSort(data.value)}
            >
              <Radio value="newest" label="Newest" />
              <Radio value="oldest" label="Oldest" />
              <Radio value="aZ" label="A → Z" />
              <Radio value="zA" label="Z → A" />
            </RadioGroup>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  }, [handleUpdateProviders, providers, setSort, sort, styles]);

  return (
    <div
      className={styles.root}
      style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground2 }}
    >
      {windowSize.width > 850 ? (
        <div className={styles.mainDrawer}>
          {/* heading info */}
          <div className={styles.brandWrap}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Lottie
                animationData={cube}
                lottieRef={lottieRef}
                style={lottieStyle}
              />
              <p className={styles.brandText}>| 3D GO</p>
            </div>
            <div className={styles.agentWrap}>
              <AgentIcon agent="thingiverse" styles={agentIconStyles} />
              <AgentIcon agent="printables" styles={agentIconStyles} />
              <AgentIcon agent="cults3d" styles={agentIconStyles} />
              <AgentIcon agent="makerworld" styles={agentIconStyles} />
              <AgentIcon agent="myminifactory" styles={agentIconStyles} />
            </div>
          </div>
          <div
            style={{
              backgroundColor: teamsDarkTheme.colorNeutralBackground3,
            }}
          >
            <div
              className={styles.agentH}
              style={{
                gap: "10px",
                padding: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FaSortAmountDown
                color={teamsDarkTheme.colorNeutralForeground3}
              />
              <Text
                size={400}
                weight="bold"
                style={{
                  color: teamsDarkTheme.colorNeutralForeground3,
                }}
              >
                Filter & Sort
              </Text>
            </div>
          </div>
          <div className={styles.optionsWrap}>{AccordionComponent}</div>
        </div>
      ) : (
        <div className={styles.mobileDrawer}>
          <div
            className={styles.mobileOptionsWrap}
            onClick={() => setShowModal(true)}
          >
            <div className={styles.mobileLeft}>
              <FaSortAmountDown
                color={teamsDarkTheme.colorNeutralForeground3}
              />
              {windowSize.width > 400 && <Text>Filter Settings</Text>}
            </div>
            <div className={styles.mobileLeft}>
              {Object.keys(providers).map((provider) => {
                if (!providers[provider]) return null;
                return (
                  <AgentIcon
                    agent={provider}
                    styles={{
                      width: "20px",
                      borderRadius: "5px",
                      height: "20px",
                    }}
                  />
                );
              })}
              <Divider vertical />
              <Text>{SORT_OPTIONS[sort].label}</Text>
            </div>
          </div>
          <Sheet
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            snapPoints={[500]}
            mountPoint={document.getElementById("modelsPageRef")}
          >
            <Sheet.Container
              style={{
                backgroundColor: teamsDarkTheme.colorNeutralBackground2,
                borderRadius: "25px 25px 0px 0px",
                border: `1px solid ${teamsDarkTheme.colorNeutralStroke2}`,
                boxSizing: "border-box",
                padding: "20px",
                display: "flex",
              }}
            >
              <Sheet.Header
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "50px",
                    height: "2px",
                    backgroundColor: teamsDarkTheme.colorNeutralStroke2,
                  }}
                />
              </Sheet.Header>
              <Sheet.Content
                style={{
                  display: "flex",
                }}
              >
                <Sheet.Scroller
                  style={{
                    padding: "10px 0px",
                    scrollbarWidth: "none",
                  }}
                >
                  {AccordionComponent}
                </Sheet.Scroller>
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop
              onClick={() => setShowModal(false)}
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              }}
            />
          </Sheet>
        </div>
      )}
    </div>
  );
}

export default React.memo(NewSavedFilterSort);
