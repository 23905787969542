import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { FluentProvider, teamsDarkTheme } from "@fluentui/react-components";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import * as Sentry from "@sentry/react";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));

Sentry.init({
  dsn: "https://055730b08f1aa7b0a47c8ee279e52587@o4505938268192768.ingest.sentry.io/4506414380482560",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/3dgo\.app\/*/],
    }),
  ],
});

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <FluentProvider theme={teamsDarkTheme}>
          <App />
        </FluentProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
