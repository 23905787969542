import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Image,
  Title3,
  Divider,
} from "@fluentui/react-components";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import AgentIcon from "../../Components/AgentIcon/AgentIcon";
import qs from "qs";
import createApiAgent from "../../utils/api-agent";
import ThingCard from "../../Components/ThingCard/ThingCard";
import { modelNormalizer } from "../../utils/normalizers";
import { PulseLoader } from "react-spinners";
import { Helmet } from "react-helmet-async";

const useStyles = makeStyles({
  modelScreenWrap: {
    overflowY: "scroll",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    ...shorthands.flex(1),
    ...shorthands.padding("10px"),
  },
  crunchyyy: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "1200px",
    width: "100%",
    ...shorthands.gap("10px"),
    ...shorthands.padding("10px", "0px"),
  },
  profileHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.gap("10px"),
  },
  profileImage: {
    width: "150px",
    height: "150px",
    overflow: "hidden",
    ...shorthands.borderRadius("50%"),
    ...shorthands.border("1px", "solid", teamsDarkTheme.colorNeutralStroke1),
  },
  profileTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.gap("5px"),
    ...shorthands.padding("0px", "0px", "10px", "0px"),
    // ...shorthands.boxSizing("border-box"),
    ...shorthands.borderBottom("2px", "solid", "transparent"),
    ":hover": {
      cursor: "pointer",
      ...shorthands.borderBottom(
        "2px",
        "solid",
        teamsDarkTheme.colorNeutralStroke1
      ),
    },
  },
  bottomWrap: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("10px"),
  },
  modelist: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    ...shorthands.flex(1),
  },
});

function ProviderProfile() {
  const { state, search } = useLocation();
  const navigate = useNavigate();
  const [selectedProfile, setSelectedProfile] = useState(state?.profile);
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [models, setModels] = useState(new Array(24).fill(0));
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const scrollDiv = useRef(null);

  const styles = useStyles();
  const params = useMemo(() => {
    return qs.parse(search, { ignoreQueryPrefix: true });
  }, [search]);

  const apiAgent = useMemo(() => {
    return createApiAgent(params.agent);
  }, [params.agent]);

  const onLoad = useCallback(async () => {
    let profile = state?.profile;
    if (!Boolean(profile)) {
      // fetch profile
    }
    setSelectedProfile(profile);

    // fetch models here
    const profileThings = await apiAgent.creatorThings({
      page: 1,
      identifier: selectedProfile?.identifier,
    });
    setModels(profileThings ? profileThings : []);
    setLoading(false);
  }, [apiAgent, selectedProfile, state?.profile]);

  const handleOpenUrl = useCallback(() => {
    window.open(selectedProfile?.url, "_blank", "noopener,noreferrer");
  }, [selectedProfile]);

  const handleLoadMore = useCallback(async () => {
    if (
      !models ||
      !models.length ||
      models.length % 12 !== 0 ||
      params.agent === "printables"
    )
      return; // here
    setLoadingMore(true);
    const newPage = Number(page) + 1;
    setPage(newPage);
    let moreThings = await apiAgent.creatorThings({
      identifier: selectedProfile?.identifier,
      page: newPage,
    });

    if (Array.isArray(moreThings) && moreThings.length > 0) {
      setModels((v) => [...v, ...moreThings]);
    }

    setLoadingMore(false);
  }, [models, params.agent, page, apiAgent, selectedProfile]);

  const handleScroll = useCallback(
    async (e) => {
      const { scrollHeight, scrollTop, clientHeight } = e.target;
      const isNearEnd = scrollHeight - scrollTop <= clientHeight * 1.5;
      if (isNearEnd && scrollDiv.current) {
        handleLoadMore();
        //TODO bug if all the way scrolled down
      }
    },
    [handleLoadMore]
  );

  const canonicalUrl = useMemo(() => {
    const baseUrl = window.location.pathname;
    return baseUrl + (location.search ? location.search : "");
  }, [location]);

  const handleClick = React.useCallback(
    (thing) => {
      const params = qs.stringify({
        agent: thing.goFinal_agent,
        id: thing.goFinal_shareId,
      });
      navigate(`/models/model?${params}`, { state: { thing } });
    },
    [navigate]
  );

  const methods = useMemo(() => {
    return {
      setSelectedThing: handleClick,
    };
  }, [handleClick]);

  const metaAgent = useMemo(() => {
    try {
      if (params?.agent) {
        const str = params.agent;
        return str[0].toUpperCase() + str.slice(1);
      } else {
        return "3D GO";
      }
    } catch (e) {
      return "3D GO";
    }
  }, [params?.agent]);

  useEffect(() => {
    setPage(1);
    setLoading(true);
    onLoad();
  }, [onLoad, params]);

  if (!Boolean(selectedProfile)) {
    // nav to home for now - not sharable
    return <Navigate to="/" />;
  }

  return (
    <>
      <Helmet>
        <title>{`${metaAgent} Profile`}</title>
        <meta
          name="description"
          content="View 3D Models From This Creator On Thingiverse, Makerworld, Printables, & More. 3D Model Search Engine"
        />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div
        className={styles.modelScreenWrap}
        style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
        onScroll={handleScroll}
        ref={scrollDiv}
      >
        <div className={styles.crunchyyy}>
          <div
            className={styles.profileHeader}
            style={{
              backgroundColor: teamsDarkTheme.colorNeutralBackground1,
              borderRadius: "10px",
              padding: "5px",
              border: `1px solid ${teamsDarkTheme.colorNeutralStroke1}`,
            }}
          >
            <Image
              src={selectedProfile?.thumbnail}
              className={styles.profileImage}
            />
            <div className={styles.profileTitle} onClick={handleOpenUrl}>
              <AgentIcon
                agent={params.agent}
                styles={{ width: "30px", height: "30px", borderRadius: "7px" }}
              />
              <Title3>{selectedProfile?.name}</Title3>
            </div>
          </div>
          <div className={styles.bottomWrap}>
            <Title3>Uploads</Title3>
            <Divider />
            <div className="SuperWrap">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "5px",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                {models &&
                  models.map((thing, i) => {
                    const normalThing =
                      thing !== 0 && modelNormalizer(thing, params.agent);

                    return (
                      <ThingCard
                        thing={normalThing}
                        agent={params.agent}
                        loading={loading}
                        methods={methods}
                        key={i}
                      />
                    );
                  })}
              </div>
              {loadingMore && <PulseLoader color="white" />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(ProviderProfile);
