import { Button, teamsDarkTheme, Text } from "@fluentui/react-components";
import React, { useCallback } from "react";
import { isIOS, isMobile } from "react-device-detect";
import { Sheet } from "react-modal-sheet";

const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.thingiverse.explore&hl=en&gl=US";
const APP_STORE_URL =
  "https://apps.apple.com/us/app/thingiverse-printables-3d-go/id6462052942";

const MobilePromo = () => {
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    // CHECK IF SHOULD SHOW PROMO
    const showPromo = sessionStorage.getItem("hasSeenMobilePromo");
    if (!showPromo && isMobile) {
      setShowModal(true);
      sessionStorage.setItem("hasSeenMobilePromo", "true");
    }
  }, []);

  const handleAppStoreRedirect = useCallback(() => {
    const finalLink = isIOS ? APP_STORE_URL : PLAY_STORE_URL;
    window.open(finalLink, "_blank");
  }, []);

  return (
    <Sheet
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      snapPoints={[300]}
      mountPoint={document.getElementById("app-root-container")}
    >
      <Sheet.Container
        style={{
          backgroundColor: teamsDarkTheme.colorNeutralBackground2,
          borderRadius: "25px 25px 0px 0px",
          border: `1px solid ${teamsDarkTheme.colorNeutralStroke2}`,
          boxSizing: "border-box",
          padding: "20px",
          display: "flex",
        }}
      >
        <Sheet.Header
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "50px",
              height: "2px",
              backgroundColor: teamsDarkTheme.colorNeutralStroke2,
            }}
          />
        </Sheet.Header>
        <Sheet.Content
          style={{
            display: "flex",
            padding: "10px 0px",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {/* CONTENT */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "20px",
              flex: 1,
            }}
          >
            <Text size={500} weight="bold" align="center">
              Continue In The Mobile App 🚀
            </Text>
            <Text align="center" size={400}>
              Gain access to exclusive features, content, and enjoy a mobile
              optimized experience.
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Button
              appearance="primary"
              style={{
                width: "100%",
                padding: "15px",
              }}
              onClick={handleAppStoreRedirect}
            >
              Open Mobile App 🥳
            </Button>
            <Button
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop
        onTap={(e) => e.stopPropagation()}
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        }}
      />
    </Sheet>
  );
};

export default React.memo(MobilePromo);
