import React from "react";
import { isIOS, isMobile } from "react-device-detect";
import { Navigate } from "react-router-dom";

const SearchAd = () => {
  if (isMobile) {
    if (isIOS) {
      window.location.href =
        "https://apps.apple.com/us/app/thingiverse-printables-3d-go/id6462052942";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.thingiverse.explore&hl=en_US&gl=US";
    }
  } else {
    return <Navigate to="/models?show_onboarding=true" />;
  }

  return <Navigate to="/models" />;
};

export default React.memo(SearchAd);
