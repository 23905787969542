import React, { useCallback, useMemo } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Text,
} from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { modelNormalizer } from "../../utils/normalizers";
import qs from "qs";
import ThingCard from "../../Components/ThingCard/ThingCard";
import createApiAgent from "../../utils/api-agent";
import { Pagination } from "@mui/material";
import { BsInfoCircle } from "react-icons/bs";
import { MdTrendingUp } from "react-icons/md";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    ...shorthands.padding("20px"),
    ...shorthands.gap("20px"),
    boxSizing: "border-box",
    flexGrow: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    alignItems: "center",
  },
  resultsWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    ...shorthands.gap("5px"),
    ...shorthands.flex(1),
  },
});

function ModelsComponent({
  page,
  setPage,
  sort,
  setSort,
  category,
  setCategory,
  providers,
  setProviders,
}) {
  const styles = useStyles();
  const navigate = useNavigate();
  const apiAgent = useMemo(() => createApiAgent("blend"), []);
  const [things, setThings] = React.useState(new Array(24).fill(0));
  const [loading, setLoading] = React.useState(true);

  const loadModels = useCallback(async () => {
    setLoading(true);
    const params = {
      sort: sort,
      providers,
      page: page,
      limit: 24,
    };
    let func;
    let results;

    func = apiAgent.getModelsByCategory;
    params.category = category;
    results = await func(params);

    Array.isArray(results) && setThings(results);
    setLoading(false);
  }, [category, sort, providers, page, apiAgent]);

  React.useEffect(() => {
    loadModels();
  }, [loadModels]);

  const handleClick = React.useCallback(
    (thing) => {
      const params = qs.stringify({
        agent: thing.goFinal_agent,
        id: thing.goFinal_shareId,
      });
      navigate(`/models/model?${params}`, { state: { thing } });
    },
    [navigate]
  );

  const handlePageChange = React.useCallback(
    (e, value) => {
      const modelsPageRef = document.getElementById("modelsPageRef");
      modelsPageRef.scrollTo({
        top: 0,
        behavior: "smooth", // Optional, smooth scrolling animation
      });
      setPage(value);
    },
    [setPage]
  );

  const methods = React.useMemo(() => {
    return {
      setSelectedThing: handleClick,
    };
  }, [handleClick]);

  const extraPages = useMemo(() => {
    return things.length === 0 ? 0 : 1;
  }, [things.length]);

  return (
    <div
      className={styles.root}
      style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "5px",
          backgroundColor: teamsDarkTheme.colorNeutralBackground1,
          padding: "10px",
          borderRadius: "5px",
          border: `1px solid ${teamsDarkTheme.colorNeutralStroke1}`,
        }}
      >
        <Text
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
          weight="semibold"
          size={isMobile ? 600 : 800}
        >
          <MdTrendingUp />
          {sort === "popular" ? "Most Popular" : "Newest"} 3D Models Right Now
        </Text>

        <Text
          style={{
            opacity: 0.5,
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
          size={200}
        >
          <BsInfoCircle />
          Type in the search bar above & find anything you want
        </Text>
      </div>
      <div className={styles.resultsWrap}>
        {things && things.length ? (
          <>
            {things.map((thing, i) => {
              const normalThing =
                thing !== 0 && modelNormalizer(thing, thing.blendSource);
              return (
                <ThingCard
                  thing={normalThing}
                  agent={thing.blendSource}
                  loading={loading}
                  methods={methods}
                  key={i}
                />
              );
            })}
          </>
        ) : (
          "No Results Found..."
        )}
      </div>
      <Pagination
        variant="outlined"
        shape="rounded"
        page={page}
        count={page + extraPages}
        onChange={handlePageChange}
        sx={{
          ".MuiPaginationItem-root": {
            color: teamsDarkTheme.colorNeutralForeground1,
            borderColor: teamsDarkTheme.colorNeutralStroke1,
          },
          ".MuiPaginationItem-root:hover": {
            borderColor: teamsDarkTheme.colorBrandStroke1,
          },
          ".Mui-selected": {
            opacity: 0.5,
          },
        }}
      />
    </div>
  );
}

export default React.memo(ModelsComponent);
