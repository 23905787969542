import * as React from "react";
import {
  Select,
  makeStyles,
  shorthands,
  teamsDarkTheme,
  useId,
} from "@fluentui/react-components";

import { StlViewer } from "react-stl-viewer";
import createApiAgent from "../utils/api-agent";
import { BarLoader } from "react-spinners";
import { HexColorPicker } from "react-colorful";
const url2 = "https://cdn.thingiverse.com/assets/1a/09/af/99/ed/t-pik09.STL";

// const proxyUrl =
//   "http://127.0.0.1:5001/thingiverse-explore/us-central1/proxyImage?agent=printables&url="; //DEV
const proxyUrl =
  "https://us-central1-thingiverse-explore.cloudfunctions.net/proxyImage?agent=printables&url=";

const useStyles = makeStyles({
  entireWrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    ...shorthands.gap("10px"),
  },
  viewer: {
    flexGrow: 1,
    display: "flex",
    width: "100%",
    height: "100%",
  },
  previewerHolder: {
    position: "relative",
  },
  loaderWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "black",
    position: "absolute",
    top: 0,
    left: 0,
  },
  Select: {
    width: "100%",
    "@media screen and (max-width: 850px)": {
      width: "200px",
      marginBottom: "10px",
    },
  },
});

const ThingCardPreview = ({ width, height, selectedThing }) => {
  const styles = useStyles();
  const selectId = useId();
  const modelRef = React.useRef(null);

  const apiAgent = createApiAgent(selectedThing?.goFinal_agent);
  const isPrintables = React.useMemo(
    () => selectedThing?.goFinal_agent === "printables",
    [selectedThing]
  );
  const [loading, setLoading] = React.useState(true);
  const [files, setFiles] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState(null);

  const [showPicker, setShowPicker] = React.useState(false);
  const [modelColor, setModelColor] = React.useState(
    teamsDarkTheme.colorBrandStroke1
  );

  const handleSelectChange = React.useCallback((val) => {
    setLoading(true);
    setSelectedFile({ direct_url: val.target.value });
  }, []);

  const previewSetup = React.useCallback(async () => {
    const response = await apiAgent.getPreviewFiles({
      id: selectedThing.goFinal_id,
    });
    if (response && response.length > 0) {
      setFiles(response);
      setSelectedFile(response[0]);
    } else {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    previewSetup();
  }, [previewSetup]);

  return (
    <div className={styles.entireWrap}>
      <div
        className={styles.previewerHolder}
        style={{
          width: width,
          height: height - 50,
        }}
      >
        {selectedFile && (
          <StlViewer
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              cursor: "grab",
            }}
            orbitControls={true}
            shadows={true}
            modelProps={{
              ref: modelRef,
              scale: 1,
              color: modelColor,
              positionX: 250,
              positionY: 250,
              rotationX: 0,
              rotationY: 0,
              rotationZ: 0,
            }}
            floorProps={{ gridLength: 500, gridWidth: 500 }}
            onFinishLoading={() => setLoading(false)}
            cameraProps={{
              initialPosition: {
                distance: 0.8,
              },
            }}
            url={
              isPrintables
                ? proxyUrl + selectedFile.direct_url
                : selectedFile.direct_url
            }
          />
        )}
        {loading && (
          <div className={styles.loaderWrap}>
            <BarLoader color="white" loading size={100} />
          </div>
        )}
        {!loading && !selectedFile && (
          <div className={styles.loaderWrap}>Preview Not Available</div>
        )}
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            margin: "20px",
            zIndex: 100,
          }}
        >
          {showPicker ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <HexColorPicker
                color={modelColor}
                onChange={setModelColor}
                style={{ maxWidth: "50vw", maxHeight: "50vh" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "10px",
                  cursor: "pointer",
                }}
                onClick={() => setShowPicker(false)}
              >
                close
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "45px",
                height: "20px",
                backgroundColor: modelColor,
                borderRadius: 7,
                border: "solid lightgrey 2px",
                boxSizing: "border-box",
                cursor: "pointer",
              }}
              onClick={() => setShowPicker(true)}
            />
          )}
        </div>
      </div>
      <Select
        onChange={handleSelectChange}
        id={selectId}
        className={styles.Select}
      >
        {files.map((item, i) => (
          <option value={item.direct_url} key={i}>
            File {i + 1}
          </option>
        ))}
      </Select>
    </div>
  );
};

export default React.memo(ThingCardPreview);
