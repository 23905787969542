import React, { useCallback, useMemo } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Text,
  Divider,
} from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { modelNormalizer } from "../../utils/normalizers";
import qs from "qs";
import ThingCard from "../../Components/ThingCard/ThingCard";
import createApiAgent from "../../utils/api-agent";
import { Pagination } from "@mui/material";
import { BsInfoCircle } from "react-icons/bs";
import CollectionCard from "../../Components/CollectionCard";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../hooks/useFirebase";

const searchCollectionsByCategory = async (category, params) => {
  const finalStr = category
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

  let q;

  const orderByField =
    params.sort === "newest" || params.sort === "oldest"
      ? "created_at"
      : "title";
  const direction =
    params.sort === "oldest" || params.sort === "aZ" ? "asc" : "desc";

  q = query(
    collection(db, "CollectionTagJoinTable"),
    where("tag_id", "==", finalStr),
    orderBy(orderByField, direction)
  );

  const joinTableSnapshot = await getDocs(q);

  const collectionArr = [];

  for (const item of joinTableSnapshot.docs) {
    const collectionDocRef = doc(db, "Collections", item.data().collectionId);
    const collectionDocSnap = await getDoc(collectionDocRef);
    if (collectionDocSnap.exists() && !collectionDocSnap.data().isPrivate) {
      collectionArr.push({
        uid: collectionDocSnap.id,
        ...collectionDocSnap.data(),
      });
    }
  }

  return collectionArr.slice(
    (params.page - 1) * params.limit,
    params.page * params.limit
  );
};

const searchCollectionsByText = async (text, params) => {
  const API_KEY = "455e84c2a08fc30d8306dcc58421f8c1";
  const APPLICATION_ID = "6FN0S4K4D0";
  const parameters = {
    query: text,
    hitsPerPage: params.limit,
    page: params.page - 1,
    filters: "isPrivate:false",
  };
  const queryString = qs.stringify(parameters);
  let index = "Collections";
  if (params.sort === "newest") {
    index = "Collections_created_at_newest";
  } else if (params.sort === "oldest") {
    index = "Collections_created_at_oldest";
  } else if (params.sort === "aZ") {
    index = "Collections_title_az";
  } else if (params.sort === "zA") {
    index = "Collections_title_za";
  }

  try {
    const resp = await fetch(
      `https://${APPLICATION_ID}-dsn.algolia.net/1/indexes/${index}?${queryString}`,
      {
        method: "GET",
        headers: {
          "X-Algolia-API-Key": API_KEY,
          "X-Algolia-Application-Id": APPLICATION_ID,
        },
      }
    );
    let parsedCollections = await resp.json();
    if (parsedCollections.hits.length > 0) {
      parsedCollections.hits = parsedCollections.hits.map((hit) => {
        return { ...hit, uid: hit.objectID };
      });
    }
    return parsedCollections.hits;
  } catch (e) {
    console.log(e);
    return [];
  }
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    ...shorthands.padding("20px"),
    ...shorthands.gap("20px"),
    boxSizing: "border-box",
    flexGrow: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    alignItems: "center",
  },
  resultsWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    ...shorthands.gap("5px"),
    ...shorthands.flex(1),
  },
});

function ModelsComponent({ page, setPage, sort, type, providers, searchText }) {
  const styles = useStyles();
  const navigate = useNavigate();
  const apiAgent = useMemo(() => createApiAgent("blend"), []);
  const [things, setThings] = React.useState(new Array(24).fill(0));
  const [loading, setLoading] = React.useState(true);

  const loadModels = useCallback(async () => {
    setLoading(true);
    let results;

    const fetchModels = async () => {
      const params = {
        sort: sort,
        providers,
        page: page,
        limit: 24,
      };
      let func;
      const isTag = searchText.toLowerCase().startsWith("tag:");
      const searchString = isTag ? searchText.slice(4) : searchText;
      func = isTag ? apiAgent.getModelsByCategory : apiAgent.searchModels;
      if (isTag) {
        params.category = searchString;
      } else {
        params.searchText = searchText;
      }
      return await func(params);
    };
    const fetchCollections = async () => {
      const params = {
        sort: sort,
        page: page,
        limit: 12,
      };
      let func;
      const isTag = searchText.toLowerCase().startsWith("tag:");
      const searchString = isTag ? searchText.slice(4) : searchText;
      func = isTag ? searchCollectionsByCategory : searchCollectionsByText;
      if (isTag) {
        params.category = searchString;
      } else {
        params.searchText = searchText;
      }
      return await func(searchString, params);
    };

    if (type === "collections") {
      results = await fetchCollections();
    } else {
      results = await fetchModels();
    }

    Array.isArray(results) && setThings(results);
    setLoading(false);
  }, [type, sort, providers, page, apiAgent, searchText]);

  React.useEffect(() => {
    loadModels();
  }, [loadModels]);

  const handleClick = React.useCallback(
    (thing) => {
      const params = qs.stringify({
        agent: thing.goFinal_agent,
        id: thing.goFinal_shareId,
      });
      navigate(`/models/model?${params}`, {
        state: { thing },
      });
    },
    [navigate]
  );

  const handlePageChange = React.useCallback(
    (e, value) => {
      const modelsPageRef = document.getElementById("searchPageRef");
      modelsPageRef.scrollTo({
        top: 0,
        behavior: "smooth", // Optional, smooth scrolling animation
      });
      setPage(value);
    },
    [setPage]
  );

  const methods = React.useMemo(() => {
    return {
      setSelectedThing: handleClick,
    };
  }, [handleClick]);

  const extraPages = useMemo(() => {
    return things.length === 0 ? 0 : 1;
  }, [things.length]);

  return (
    <div
      className={styles.root}
      style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <Text
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
          weight="semibold"
          size={900}
        >
          Search Results for: '{searchText}'
        </Text>

        <Text
          style={{
            opacity: 0.5,
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
          size={200}
        >
          <BsInfoCircle />
          Start your search with 'tag:' to search any category
        </Text>
      </div>
      <Divider
        style={{
          flex: 0,
        }}
      />
      <div className={styles.resultsWrap}>
        {things && things.length ? (
          <>
            {type === "collections" ? (
              <>
                {things.map((c, i) => {
                  return (
                    <div key={i}>
                      <CollectionCard collection={c} loading={loading} />
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {things.map((thing, i) => {
                  const normalThing =
                    thing !== 0 && modelNormalizer(thing, thing.blendSource);
                  return (
                    <ThingCard
                      thing={normalThing}
                      agent={thing.blendSource}
                      loading={loading}
                      methods={methods}
                      key={i}
                    />
                  );
                })}
              </>
            )}
          </>
        ) : (
          "No Results Found..."
        )}
      </div>
      <Pagination
        variant="outlined"
        shape="rounded"
        page={page}
        count={page + extraPages}
        onChange={handlePageChange}
        sx={{
          ".MuiPaginationItem-root": {
            color: teamsDarkTheme.colorNeutralForeground1,
            borderColor: teamsDarkTheme.colorNeutralStroke1,
          },
          ".MuiPaginationItem-root:hover": {
            borderColor: teamsDarkTheme.colorBrandStroke1,
          },
          ".Mui-selected": {
            opacity: 0.5,
          },
        }}
      />
    </div>
  );
}

export default React.memo(ModelsComponent);
