import React from "react";
import { Route, Routes } from "react-router-dom";
import CollectionsPage from "../screens/CollectionsPage/CollectionsPage";
import CollectionPage from "../screens/CollectionPage/CollectionPage";

const CollectionsRoute = () => {
  return (
    <Routes>
      <Route path="me" element={<CollectionsPage />} />
      <Route path="collection" element={<CollectionPage />} />
    </Routes>
  );
};

export default React.memo(CollectionsRoute);
