import { makeStyles } from "@fluentui/react-components";
import React, { useCallback, useEffect } from "react";
import { BarLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { setRevenueCat } from "../../store/items/user";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexGrow: 1,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

function StripeRedirect() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePaymentComplete = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const rcResp = urlParams.get("revenueCatResp");
    const obj = JSON.parse(rcResp);
    obj && dispatch(setRevenueCat(obj));
    navigate(`/models`);
  }, [dispatch, navigate]);

  useEffect(() => {
    handlePaymentComplete();
  }, [handlePaymentComplete]);

  return (
    <div className={styles.main}>
      <BarLoader color="white" />
    </div>
  );
}

export default React.memo(StripeRedirect);
