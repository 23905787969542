import "./WebSlicer.css";
import { useRef } from "react";
import { makeStyles, shorthands } from "@fluentui/react-components";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";

const useStyles = makeStyles({
  main: {
    ...shorthands.flex(1),
    position: "relative",
    backgroundColor: "black",
  },
  frame: {
    width: "100%",
    height: "100%",
    ...shorthands.border("none"),
  },
});

function WebSlicer() {
  const modelRef = useRef(null);
  const styles = useStyles();
  const revenueCat = useSelector((state) => state.user.revenueCat);
  const loadingUser = useSelector((state) => state.user.loading);

  if (loadingUser) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Cloud Based 3D Printing Slicer</title>
        <meta
          name="description"
          content="Slice 3D Models Online With Our Javascipt Based 3D Printing Slicer Software"
        />
        <link rel="canonical" href="/slicer" />
      </Helmet>
      <div className={styles.main}>
        <iframe
          // src="http://localhost:8080/kiri" //DEV
          src="https://threedgo-grid-apps.onrender.com/kiri/" //PROD
          className={styles.frame}
          id="fbody"
          ref={modelRef}
          title="frame"
        ></iframe>
      </div>
    </>
  );
}

export default WebSlicer;
