import React, { useMemo } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
} from "@fluentui/react-components";
import ContestFilterSort from "./ContestFilterSort";
import ContestsComponent from "./ContestsComponent";
import { Helmet } from "react-helmet-async";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    height: "100%",
    overflowY: "scroll",
    alignItems: "center",
    boxSizing: "border-box",
  },
  crunchWrapSupreme: {
    display: "flex",
    ...shorthands.flex(1),
    flexDirection: "column",
    width: "100%",
    maxWidth: "1200px",
    boxSizing: "border-box",
  },
  mainBody: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.flex(1),
    alignItems: "flex-start",
    boxSizing: "border-box",
    justifyContent: "flex-start",
    "@media (max-width: 850px)": {
      flexDirection: "column",
    },
  },
});

function ContestsPage() {
  const styles = useStyles();
  const [providers, setProviders] = React.useState({
    printables: true,
    makerworld: true,
  });

  const props = useMemo(() => {
    return {
      providers,
      setProviders,
    };
  }, [providers]);

  return (
    <>
      <Helmet>
        <title>3D Model Contests - Makerworld & Printables</title>
        <meta
          name="description"
          content="Search 3D Model Contests From Makerworld and Printables"
        />
        <link rel="canonical" href="/contests" />
      </Helmet>
      <div
        className={styles.root}
        style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
        id="modelsPageRef"
      >
        <div className={styles.crunchWrapSupreme}>
          <div className={styles.mainBody}>
            <ContestFilterSort {...props} />
            <ContestsComponent {...props} />
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(ContestsPage);
