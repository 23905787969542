import React from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
} from "@fluentui/react-components";
import { Navigate, useNavigate } from "react-router-dom";
import NewMyProfileTop from "./NewMyProfileTop";
import NewMyProfileBody from "./NewMyProfileBody";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    height: "100%",
    overflowY: "auto",
    alignItems: "center",
    boxSizing: "border-box",
  },
  crunchWrapSupreme: {
    display: "flex",
    ...shorthands.flex(1),
    flexDirection: "column",
    width: "100%",
    maxWidth: "1200px",
    boxSizing: "border-box",
  },
  mainBody: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.flex(1),
    alignItems: "flex-start",
    boxSizing: "border-box",
    justifyContent: "flex-start",
    "@media (max-width: 850px)": {
      flexDirection: "column",
    },
  },
});

function NewMyProfile() {
  const styles = useStyles();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const loadingUser = useSelector((state) => state.user.loading);

  if (loadingUser) {
    return null;
  }

  if (!user && !loadingUser) {
    return <Navigate to="/models" replace />;
  }

  return (
    <>
      <Helmet>
        <title>My Profile</title>
        <meta
          name="description"
          content="View Your 3D GO Profile. Make Changes. Log in / Out."
        />
        <link rel="canonical" href="/me" />
      </Helmet>
      <div
        className={styles.root}
        style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
        id="modelsPageRef"
      >
        <div className={styles.crunchWrapSupreme}>
          <div className={styles.mainBody}>
            <NewMyProfileTop />
            <NewMyProfileBody />
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(NewMyProfile);
