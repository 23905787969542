import React, { useCallback, useMemo, useRef } from "react";
import {
  Button,
  Divider,
  Image,
  Input,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  shorthands,
  Tab,
  TabList,
  teamsDarkTheme,
  Text,
} from "@fluentui/react-components";
import { useLocation, useNavigate } from "react-router-dom";
import "./MainHeader.css";
import Lottie from "lottie-react";
import cube from "./logo-lottie1.json";
import {
  BsBookmark,
  BsChevronDown,
  BsChevronRight,
  BsPerson,
} from "react-icons/bs";
import { MdBookmark, MdCollectionsBookmark, MdSearch } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setShowModal } from "../store/items/user";
import { createAvatar } from "@dicebear/core";
import { thumbs } from "@dicebear/collection";
import { FaCube, FaCubes, FaTrophy } from "react-icons/fa";
import qs from "qs";
import { useWindowSize } from "@uidotdev/usehooks";
import { Drawer, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { isIOS } from "react-device-detect";
import AgentIcon from "./AgentIcon/AgentIcon";
import { signOut } from "firebase/auth";
import { auth } from "../hooks/useFirebase";
import MobilePromo from "./MobilePromo";

const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.thingiverse.explore&hl=en&gl=US";
const APP_STORE_URL =
  "https://apps.apple.com/us/app/thingiverse-printables-3d-go/id6462052942";

const useStyles = makeStyles({
  mainHeader: {
    backgroundColor: teamsDarkTheme.colorNeutralBackground1,
    boxShadow: teamsDarkTheme.shadow2,
    display: "flex",
    justifyContent: "center",
    ...shorthands.borderBottom(
      "1px",
      "solid",
      teamsDarkTheme.colorNeutralStroke2
    ),
  },
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    ...shorthands.padding("10px", "20px"),
    position: "sticky",
    top: 0,
    zIndex: 100,
    flexWrap: "wrap",
    width: "100%",
    maxWidth: "1200px",
    ...shorthands.gap("20px"),
  },
  myButtons: {
    ...shorthands.padding("5px"),
    ...shorthands.borderRadius("5px"),
    ...shorthands.border("1px", "solid", teamsDarkTheme.colorNeutralStroke2),
    ...shorthands.gap("5px"),
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    ":hover": {
      cursor: "pointer",
      backgroundColor: teamsDarkTheme.colorNeutralBackground1Hover,
    },
  },
  right: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
      backgroundColor: teamsDarkTheme.colorNeutralBackground1Hover,
    },
    // ...shorthands.border("1px", "solid", teamsDarkTheme.colorNeutralStroke2),
    ...shorthands.borderRadius("5px"),
    ...shorthands.padding("5px", "10px"),
    ...shorthands.gap("10px"),
  },
  avatar: {
    width: "35px",
    height: "35px",
    ...shorthands.borderRadius("50%"),
    ...shorthands.border("1px", "solid", teamsDarkTheme.colorBrandStroke1),
  },
  avatar1: {
    width: "30px",
    height: "30px",
    ...shorthands.borderRadius("50%"),
    ...shorthands.border("1px", "solid", teamsDarkTheme.colorBrandStroke1),
  },
  left: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    maxWidth: "100vw",
  },
  Input: {
    flexGrow: 1,
    backgroundColor: teamsDarkTheme.colorNeutralBackground2,
    color: "white",
    height: "100%",
    maxWidth: "700px",
    ...shorthands.borderRadius("5px"),
    ...shorthands.border("1px", "solid", teamsDarkTheme.colorNeutralStroke2),
    ...shorthands.outline("none"),
    ":hover": {
      ...shorthands.border("1px", "solid", teamsDarkTheme.colorNeutralStroke1),
      cursor: "text",
    },
  },

  popover: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.padding("10px"),
    ...shorthands.gap("10px"),
  },
  mobileRoot: {
    display: "flex",
    position: "sticky",
    justifyContent: "space-between",
    alignItems: "center",
    top: 0,
    zIndex: 100,
    width: "100%",
    ...shorthands.padding("10px", "20px"),
    // ...shorthands.gap("15px"),
    ...shorthands.gap("0.5rem"),
    boxSizing: "border-box",
  },
  mobileLeft: {
    display: "flex",
    alignItems: "center",
    ...shorthands.gap("10px"),
  },
  mobileAvatarWrap: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "center",
    ...shorthands.gap("5px"),
    ...shorthands.borderRadius("5px"),
    ...shorthands.border("1px", "solid", teamsDarkTheme.colorNeutralStroke2),
    ...shorthands.padding("5px"),
  },
  mainDrawer: {
    width: "250px",
    minWidth: "40vw",
    maxHeight: "100dvh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
    ...shorthands.padding("30px", "0px", "20px", "0px"),
    ...shorthands.gap("20px"),
    backgroundColor: teamsDarkTheme.colorNeutralBackground2,
  },
  menuItems: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    ...shorthands.gap("15px"),
    width: "70%",
    ...shorthands.flex(1),
  },
  brandText: {
    ...shorthands.padding("0px"),
    ...shorthands.margin("0px"),
    color: "white",
    fontWeight: "bold",
    fontSize: "24px",
  },
  brandWrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.gap("5px"),
  },
  MobileInput: {
    width: "80%",
    color: "white",
    backgroundColor: teamsDarkTheme.colorNeutralBackground1,
    ...shorthands.borderRadius("5px"),
    ...shorthands.padding("10px", "15px"),
    // fontSize: "14px",
    ...shorthands.border("1px", "solid", teamsDarkTheme.colorBrandBackground),
  },
  drawerButton: {
    minWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.gap("10px"),
    color: "white",
    fontSize: "14px",
    fontWeight: "bold",
    ...shorthands.padding("10px", "15px"),
    ...shorthands.borderRadius("5px"),
    backgroundColor: teamsDarkTheme.colorBrandBackground,
    ":active": {
      opacity: 0.5,
    },
    ":hover": {
      cursor: "pointer",
    },
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "70%",
    ...shorthands.gap("10px"),
  },
  mobileText: {
    ...shorthands.padding("0px"),
    ...shorthands.margin("0px"),
    fontSize: "12px",
    color: "white",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    ...shorthands.gap("5px"),
    ":active": {
      opacity: 0.5,
    },
  },
  agentWrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.gap("5px"),
    width: "100%",
  },
});

function MainHeader() {
  const user = useSelector((state) => state.user.value);
  const collections = useSelector((state) => state.things.collections);
  const likes = useSelector((state) => state.likes.likesArr);
  const styles = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams =
    location?.search && qs.parse(location.search, { ignoreQueryPrefix: true });
  const navigate = useNavigate();
  const tabListValue = location.pathname.split("/")[1];
  const lottieRef = useRef(null);
  const windowSize = useWindowSize();
  const [showDrawer, setShowDrawer] = React.useState(false);

  const [searchText, setSearchText] = React.useState("");

  const handleNoUser = useCallback(() => {
    dispatch(setShowModal(true));
  }, [dispatch]);

  const lottieStyle = {
    width: `40px`,
    height: "40px",
    cursor: "pointer",
    marginRight: "10px",
    borderRadius: "5px",
  };

  const mobileLottieStyle = {
    width: `120px`,
    height: "120px",
    cursor: "pointer",
  };

  const showHeader = useMemo(() => {
    return queryParams?.header === "false" ? false : true;
  }, [queryParams]);

  const avatar = useMemo(() => {
    return createAvatar(thumbs, {
      seed: "Aneka",
      scale: 75,
      size: 40,
      backgroundColor: [teamsDarkTheme.colorNeutralBackground2.slice(1)],
      shapeColor: [teamsDarkTheme.colorBrandBackground.slice(1)],
      eyes: ["variant4W16"],
    }).toDataUriSync();
  }, []);

  const handleAvatar = useCallback(() => {
    if (!user) {
      handleNoUser();
    } else {
      navigate(`/me`);
    }
    setShowDrawer(false);
  }, [handleNoUser, navigate, user]);

  const handleAppStore = useCallback(() => {
    const location = isIOS ? APP_STORE_URL : PLAY_STORE_URL;
    window.open(location, "_blank");
  }, []);

  const handleNavigate = useCallback(
    (path) => {
      if (path === "/ai_generation" || path === "/slicer") {
        navigate(path);
      } else if (path === "/saved" && !user) {
        handleNoUser();
      } else if (path === "/collections/me" && !user) {
        handleNoUser();
      } else {
        navigate(path);
      }
      setShowDrawer(false);
    },
    [handleNoUser, navigate, user]
  );

  const handleSearch = useCallback(() => {
    if (!searchText) return;
    else if (searchText.trim() === "") return;
    setSearchText("");
    navigate(`/search?q=${encodeURIComponent(searchText.trim())}`);
  }, [navigate, searchText]);

  const handleLogout = useCallback(() => {
    signOut(auth);
    navigate(`/models`);
  }, [navigate]);

  const handleKeydown = useCallback(
    (e) => {
      if (e.key === "Enter") {
        handleSearch();
      }
    },
    [handleSearch]
  );

  const agentIconStyles = {
    width: "30px",
    height: "30px",
    borderRadius: "10px",
  };

  if (
    tabListValue === "i_love_madisun" ||
    tabListValue === "stripe-purchase" ||
    tabListValue === "i_love_madisun2" ||
    tabListValue === "i_love_madisun3" ||
    tabListValue === "i_love_madisun4" ||
    tabListValue === "i_love_madisun5" ||
    !showHeader
  ) {
    return <></>;
  }

  return (
    <>
      <div className={styles.mainHeader}>
        {windowSize.width > 850 ? (
          <div className={styles.root}>
            <div className={styles.left}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Lottie
                  animationData={cube}
                  lottieRef={lottieRef}
                  style={lottieStyle}
                  onClick={() => handleNavigate(`/models`)}
                />
                <Divider vertical />
              </div>
              <TabList
                selectedValue={tabListValue}
                className="tablist"
                style={{
                  margin: 0,
                }}
              >
                <Tab value="models" onClick={() => handleNavigate(`/models`)}>
                  Models
                </Tab>
                <Tab
                  value="contests"
                  onClick={() => handleNavigate(`/contests`)}
                >
                  Contests
                </Tab>
                <Tab value="slicer" onClick={() => handleNavigate(`/slicer`)}>
                  Slicer
                </Tab>
              </TabList>
            </div>
            <Input
              className={styles.Input}
              onKeyDown={handleKeydown}
              placeholder="Search Millions..."
              appearance="transparent"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              contentAfter={
                <div onClick={handleSearch}>
                  <Button
                    appearance="transparent"
                    icon={<MdSearch />}
                    size="small"
                  />
                </div>
              }
            />
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <div
                className={styles.myButtons}
                style={{
                  borderColor: location.pathname.includes("collections/me")
                    ? teamsDarkTheme.colorBrandBackground
                    : null,
                }}
                onClick={() => handleNavigate("/collections/me")}
              >
                <MdCollectionsBookmark
                  size={20}
                  color={
                    location.pathname.includes("collections/me")
                      ? teamsDarkTheme.colorBrandBackground
                      : "white"
                  }
                />
                <Text size={200} weight={"semibold"}>
                  {collections.length}
                </Text>
              </div>
              <div
                className={styles.myButtons}
                style={{
                  borderColor: tabListValue.includes("saved")
                    ? teamsDarkTheme.colorBrandBackground
                    : null,
                }}
                onClick={() => handleNavigate("/saved")}
              >
                <BsBookmark
                  size={20}
                  color={
                    tabListValue.includes("saved")
                      ? teamsDarkTheme.colorBrandBackground
                      : "white"
                  }
                />
                <Text size={200} weight={"semibold"}>
                  {likes.length}
                </Text>
              </div>
              <Divider vertical />
              <Menu positioning="below">
                <MenuTrigger disableButtonEnhancement>
                  <div className={styles.right}>
                    <Image
                      src={user?.photoURL ? user.photoURL : avatar}
                      className={styles.avatar}
                      alt="3d-go-user-icon"
                      title="3D GO User Icon"
                      fit="contain"
                    />
                    {windowSize.width > 950 && (
                      <div
                        style={{
                          display: "flex",
                          gap: "2px",
                          flexDirection: "column",
                          minWidth: "75px",
                          maxWidth: "200px",
                          flexWrap: "nowrap",
                        }}
                      >
                        <Text
                          size={200}
                          weight={"semibold"}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {!user
                            ? "Login / Sign up"
                            : user?.displayName
                            ? user.displayName
                            : "Awesome User"}
                        </Text>
                        <Text
                          size={100}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {!user
                            ? "🖤🖤🖤"
                            : user?.email
                            ? user.email
                            : "No Email"}
                        </Text>
                      </div>
                    )}
                    <BsChevronDown />
                  </div>
                </MenuTrigger>

                <MenuPopover
                  style={{
                    width: "200px",
                    backgroundColor: teamsDarkTheme.colorNeutralBackground2,
                  }}
                >
                  <MenuList>
                    {user ? (
                      <>
                        <MenuItem onClick={handleAvatar}>View Account</MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                      </>
                    ) : (
                      <MenuItem onClick={handleNoUser}>
                        Login / Sign up
                      </MenuItem>
                    )}
                  </MenuList>
                </MenuPopover>
              </Menu>
            </div>
          </div>
        ) : (
          <>
            <div className={styles.mobileRoot}>
              <div className={styles.mobileLeft}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{
                    borderRadius: "5px",
                    border: `1px solid ${teamsDarkTheme.colorNeutralStroke2}`,
                  }}
                  onClick={() => setShowDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
                {windowSize.width > 375 && (
                  <Lottie
                    animationData={cube}
                    lottieRef={lottieRef}
                    style={{
                      width: `40px`,
                      height: "40px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleNavigate(`/models`)}
                  />
                )}
              </div>
              <Input
                className={styles.Input}
                onKeyDown={handleKeydown}
                placeholder="Search Millions..."
                appearance="transparent"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                contentAfter={
                  <div onClick={handleSearch}>
                    <Button
                      appearance="transparent"
                      icon={<MdSearch />}
                      size="small"
                      // onClick={handleSearch}
                    />
                  </div>
                }
              />
              <div className={styles.mobileAvatarWrap} onClick={handleAvatar}>
                <Image
                  src={user?.photoURL ? user.photoURL : avatar}
                  className={styles.avatar1}
                  fit="cover"
                />
              </div>
            </div>
            <Drawer open={showDrawer} onClose={() => setShowDrawer(false)}>
              <div className={styles.mainDrawer}>
                <div className={styles.brandWrap}>
                  <Lottie
                    animationData={cube}
                    lottieRef={lottieRef}
                    style={mobileLottieStyle}
                    onClick={() => handleNavigate(`/home`)}
                  />
                  <div className={styles.agentWrap}>
                    <AgentIcon agent="thingiverse" styles={agentIconStyles} />
                    <AgentIcon agent="printables" styles={agentIconStyles} />
                    <AgentIcon agent="cults3d" styles={agentIconStyles} />
                    <AgentIcon agent="makerworld" styles={agentIconStyles} />
                    <AgentIcon agent="myminifactory" styles={agentIconStyles} />
                  </div>
                  <p className={styles.brandText}>3D GO</p>
                </div>
                <div className={styles.menuItems}>
                  <div
                    onClick={() => handleNavigate(`/models`)}
                    className={styles.drawerButton}
                  >
                    <FaCube className={styles.menuBtnIcon} size={17} />
                    3D Models
                  </div>
                  <div
                    onClick={() => handleNavigate(`/contests`)}
                    className={styles.drawerButton}
                  >
                    <FaTrophy className={styles.menuBtnIcon} size={17} />
                    Contests
                  </div>
                  <div
                    onClick={() => handleNavigate(`/slicer`)}
                    className={styles.drawerButton}
                  >
                    <FaCubes size={19} className={styles.menuBtnIcon} />
                    Slicer
                  </div>
                  <div
                    style={{
                      height: "2px",
                      width: "100%",
                      borderRadius: "5px",
                      backgroundColor: teamsDarkTheme.colorNeutralStroke2,
                    }}
                  />
                  <div
                    onClick={() => {
                      handleNavigate("/collections/me");
                    }}
                    className={styles.drawerButton}
                  >
                    <MdCollectionsBookmark
                      size={19}
                      className={styles.menuBtnIcon}
                    />
                    My Collections
                  </div>
                  <div
                    onClick={() => handleNavigate("/saved")}
                    className={styles.drawerButton}
                  >
                    <MdBookmark size={19} className={styles.menuBtnIcon} />
                    Saved Models
                  </div>
                </div>
                <div className={styles.footer}>
                  <p className={styles.mobileText} onClick={handleAppStore}>
                    Use the mobile app instead <BsChevronRight />
                  </p>
                  <div onClick={handleAvatar} className={styles.drawerButton}>
                    <BsPerson className={styles.menuBtnIcon} size={20} />
                    Profile
                  </div>
                </div>
              </div>
            </Drawer>
          </>
        )}
      </div>
      <MobilePromo />
    </>
  );
}

export default React.memo(MainHeader);
