import React, { useEffect, useMemo, useState } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
} from "@fluentui/react-components";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import FilterSort from "./SearchFilterSort";
import ModelsComponent from "./SearchComponent";
import { Helmet } from "react-helmet-async";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    height: "100%",
    overflowY: "scroll",
    alignItems: "center",
    boxSizing: "border-box",
  },
  crunchWrapSupreme: {
    display: "flex",
    ...shorthands.flex(1),
    flexDirection: "column",
    width: "100%",
    maxWidth: "1200px",
    boxSizing: "border-box",
  },
  mainBody: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.flex(1),
    alignItems: "flex-start",
    boxSizing: "border-box",
    justifyContent: "flex-start",
    "@media (max-width: 850px)": {
      flexDirection: "column",
    },
  },
});

function SearchPage() {
  const styles = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    console.log("searchParams", searchParams);
    if (searchParams.has("q")) {
      setSearchText(searchParams.get("q"));
    }
  }, [searchParams]);

  const q = useMemo(() => searchParams.get("q"), [searchParams]);

  const [page, setPage] = useState(
    searchParams.get("page") ? parseInt(searchParams.get("page")) : 1
  );
  const [sort, setSort] = React.useState(searchParams.get("sort") ?? "popular");
  const [providers, setProviders] = React.useState(
    JSON.parse(searchParams.get("providers")) ?? {
      thingiverse: true,
      printables: true,
      makerworld: true,
      cults3d: true,
      myminifactory: false,
    }
  );

  const [searchText, setSearchText] = useState(searchParams.get("q") ?? "");
  const [type, setType] = React.useState(searchParams.get("type") ?? "models");

  const canonicalUrl = useMemo(() => {
    const baseUrl = window.location.pathname;
    return baseUrl + (location.search ? location.search : "");
  }, [location]);

  const props = useMemo(() => {
    return {
      page,
      setPage,
      sort,
      setSort,
      type,
      setType,
      providers,
      setProviders,
      searchText,
      setSearchText,
    };
  }, [type, page, providers, searchText, sort]);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(window.location.search);
    const initProviders = {
      thingiverse: true,
      printables: true,
      makerworld: true,
      cults3d: true,
      myminifactory: false,
    };
    if (sort !== "popular") {
      newSearchParams.set("sort", sort);
    } else {
      newSearchParams.delete("sort");
    }
    if (page !== 1) {
      newSearchParams.set("page", page.toString());
    } else {
      newSearchParams.delete("page");
    }
    if (type !== "models") {
      newSearchParams.set("type", type);
    } else {
      newSearchParams.delete("type");
    }
    if (JSON.stringify(providers) !== JSON.stringify(initProviders)) {
      newSearchParams.set("providers", JSON.stringify(providers));
    } else {
      newSearchParams.delete("providers");
    }

    setSearchParams(newSearchParams);
  }, [sort, page, type, providers, setSearchParams]);

  return (
    <>
      <Helmet>
        <title>Search 3D Printer Files</title>
        <meta
          name="description"
          content={`Search Models - Model Details - 3D Previewing, Collect & Save.`}
        />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div
        className={styles.root}
        style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
        id="searchPageRef"
      >
        <div className={styles.crunchWrapSupreme}>
          <div className={styles.mainBody}>
            <FilterSort {...props} />
            <ModelsComponent {...props} />
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(SearchPage);
