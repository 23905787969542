import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { teamsDarkTheme } from "@fluentui/react-components";

const CARD_ELEMENT_OPTIONS = {
  mode: "setup",
  theme: "night",
  paymentMethodCreation: "manual",
  variables: {
    colorPrimary: teamsDarkTheme.colorBrandStroke1,
    colorBackground: teamsDarkTheme.colorNeutralBackground3,
    colorText: "white",
    colorDanger: teamsDarkTheme.colorStatusDangerForeground1,
    spacingUnit: "2px",
    borderRadius: "4px",
  },
};

//dev STRIPE TEST MODE SWITCH
// const stripePromise = loadStripe(
//   "pk_test_51O9xvtK2VAYUgcTBTZ5zgCKxcQJoAzlP1Sh2EkIEGNXeT3AF6wRcYAhiofwPwDpmkgkC495tAvY889NIxKVegU1h001mrmT5i3"
// );

//prod
const stripePromise = loadStripe(
  "pk_live_51O9xvtK2VAYUgcTBiOx04JrSkKxhZzu0kWvcBtQHZWCHls57FAwxOt7gLjb8EjLpQpm0cqFPPhNB59d54KPFWbmJ00VkjY0pnb"
);

const CheckoutTab = ({
  swiperRef,
  handleClose,
  swiperIndex,
  dispatchToast,
}) => {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    const fetchClientSecret = async () => {
      // const intentUrl =
      //   "http://127.0.0.1:5001/thingiverse-explore/us-central1/createPaymentIntent";
      const intentUrl =
        "https://us-central1-thingiverse-explore.cloudfunctions.net/createPaymentIntent";
      await fetch(intentUrl, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((data) => {
          setClientSecret(data.clientSecret);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    if (swiperIndex === 1) {
      fetchClientSecret();
    }
  }, [swiperIndex]);

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: teamsDarkTheme.colorNeutralBackground3,
        borderRadius: 5,
        justifyContent: "center",
        width: "100%",
        height: "100%",
        padding: "15px 10px",
        border: `solid ${teamsDarkTheme.colorNeutralStroke1} 1px`,
        boxShadow: teamsDarkTheme.shadow2,
        boxSizing: "border-box",
        overflowY: "auto",
        flex: 1,
      }}
    >
      {clientSecret ? (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
            appearance: CARD_ELEMENT_OPTIONS,
            loader: "always",
          }}
        >
          <CheckoutForm
            clientSecret={clientSecret}
            dispatchToast={dispatchToast}
          />
        </Elements>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            boxSizing: "border-box",
          }}
        >
          cheers ♥️
        </div>
      )}
    </div>
  );
};

export default React.memo(CheckoutTab);
