import React, { useMemo, useState } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
} from "@fluentui/react-components";
import { Navigate } from "react-router-dom";
import NewSavedFilterSort from "./NewSavedFilterSort";
import NewSavedComponent from "./NewSavedComponent";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    height: "100%",
    overflowY: "scroll",
    alignItems: "center",
    boxSizing: "border-box",
  },
  crunchWrapSupreme: {
    display: "flex",
    ...shorthands.flex(1),
    flexDirection: "column",
    width: "100%",
    maxWidth: "1200px",
    boxSizing: "border-box",
  },
  mainBody: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.flex(1),
    alignItems: "flex-start",
    boxSizing: "border-box",
    justifyContent: "flex-start",
    "@media (max-width: 850px)": {
      flexDirection: "column",
    },
  },
});

function NewSavedPage() {
  const styles = useStyles();
  const user = useSelector((state) => state.user.value);
  const loadingUser = useSelector((state) => state.user.loading);
  const [sort, setSort] = React.useState("newest");
  const [providers, setProviders] = React.useState({
    thingiverse: true,
    printables: true,
    makerworld: true,
    cults3d: true,
    myminifactory: true,
  });
  const [searchText, setSearchText] = useState("");

  const props = useMemo(() => {
    return {
      sort,
      setSort,
      providers,
      setProviders,
      searchText,
      setSearchText,
    };
  }, [providers, searchText, sort]);

  if (loadingUser) {
    return null;
  }

  if (!user && !loadingUser) {
    return <Navigate to="/models" replace />;
  }

  return (
    <>
      <Helmet>
        <title>Saved 3D Models</title>
        <meta
          name="description"
          content="View All Saved 3D Models To Your Profile Here"
        />
        <link rel="canonical" href="/saved" />
      </Helmet>
      <div
        className={styles.root}
        style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
        id="modelsPageRef"
      >
        <div className={styles.crunchWrapSupreme}>
          <div className={styles.mainBody}>
            <NewSavedFilterSort {...props} />
            <NewSavedComponent {...props} />
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(NewSavedPage);
