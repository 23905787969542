import { createSlice } from "@reduxjs/toolkit";
export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: null,
    revenueCat: null,
    showModal: false,
    showPaywall: false,
    loading: true,
  },
  reducers: {
    setUser: (state, action) => {
      state.value = action.payload;
    },
    setRevenueCat: (state, action) => {
      state.revenueCat = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setShowPaywall: (state, action) => {
      state.showPaywall = action.payload;
    },
    setUserLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUser,
  setRevenueCat,
  setShowModal,
  setShowPaywall,
  setUserLoading,
} = userSlice.actions; // TODO

export default userSlice.reducer;
