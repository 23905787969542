import * as React from "react";
import {
  makeStyles,
  shorthands,
  tokens,
  Text,
  Image,
  SkeletonItem,
  teamsDarkTheme,
} from "@fluentui/react-components";
import { Card, CardHeader, CardPreview } from "@fluentui/react-components";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { db } from "../hooks/useFirebase";
import { modelNormalizer } from "../utils/normalizers";
import moment from "moment";
import { FaCube } from "react-icons/fa";
import { ImageNotSupportedOutlined } from "@mui/icons-material";
import { createAvatar } from "@dicebear/core";
import { thumbs } from "@dicebear/collection";

// const proxyUrl =
//   "http://127.0.0.1:5001/thingiverse-explore/us-central1/proxyImage?agent=printables&url="; //DEV
const proxyUrl =
  "https://us-central1-thingiverse-explore.cloudfunctions.net/proxyImage?agent=printables&url=";

const fetchCreatorInfo = async (identifier, user) => {
  const userId = user?.uid;
  if (identifier === userId) {
    const newObj = JSON.parse(JSON.stringify(user));
    newObj.displayName = newObj.displayName || "You";
    return newObj;
  }

  const url = `https://us-central1-thingiverse-explore.cloudfunctions.net/getUser?identifier=${identifier}`;

  const resp = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await resp.json();
};
const fetchPreviewThings = async (identifier) => {
  const snapshot = await getDocs(
    query(
      collection(db, "CollectionThings"),
      where("collectionId", "==", identifier),
      limit(4)
    )
  );

  const previewThings = snapshot.docs.map((doc) => ({
    uid: doc.id,
    ...doc.data(),
  }));
  return previewThings;
};
const fetchNumItems = async (identifier) => {
  const numItems = await getDocs(
    query(
      collection(db, "CollectionThings"),
      where("collectionId", "==", identifier)
    )
  ).then((snapshot) => {
    return snapshot.docs.length;
  });
  return numItems;
};

const fetchCollectionTags = async (identifier) => {
  // Add orderBy clause for sorting by tagName in ascending order
  const q = query(
    collection(db, "CollectionTagJoinTable"),
    where("collectionId", "==", identifier)
  );
  const querySnapshot = await getDocs(q);
  const collectionTags = querySnapshot.docs.map((doc) => ({
    uid: doc.id,
    ...doc.data(),
  }));
  return collectionTags;
};

const useStyles = makeStyles({
  card: {
    minWidth: "250px",
    width: "300px",
    height: "fit-content",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "200px",
    boxSizing: "border-box",
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
    ...shorthands.border("1px", "solid", "transparent"),
    ...shorthands.outline("1px", "solid", "rgba(255, 255, 255, 0.1)"),
    boxShadow: teamsDarkTheme.shadow4,
    ":hover": {
      cursor: "pointer",
      backgroundColor: teamsDarkTheme.colorNeutralBackground3Hover,
      ...shorthands.border("1px", "solid", teamsDarkTheme.colorBrandStroke1),
    },
    "@media (max-width: 850px)": {
      // maxWidth: "40vw",
      // minWidth: "20vw", // ???
    },
  },
  caption: {
    color: tokens.colorNeutralForeground3,
    maxWidth: "200px",
    textOverflow: "ellipsis",
  },
  image: {
    maxHeight: "100px",
    height: "100px",
    minHeight: "100px",
    width: "50%",
    objectFit: "cover",
  },
  image1: {
    maxHeight: "100px",
    height: "100px",
    minHeight: "100px",
    width: "50%",
    objectFit: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  skeletonImage: {
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
    maxHeight: "200px",
    height: "200px",
    minHeight: "200px",
    width: "100%",
  },
  grayBackground: {
    backgroundColor: tokens.colorNeutralBackground3,
  },
  grayBackground1: {
    backgroundColor: tokens.colorNeutralBackground3,
  },
  logoBadge: {
    ...shorthands.padding("5px"),
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
    backgroundColor: "#FFF",
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)",
  },
  bookmark: {
    ":hover": {
      color: tokens.colorBrandForeground1,
    },
  },
  savedBookmark: {
    color: tokens.colorBrandForeground1,
  },
  loadingHeader: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    ...shorthands.gap("4px"),
  },
  loadingText1: {
    width: "75%",
  },
  loadingText2: {
    width: "50%",
  },
  imageWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    // justifyContent: "space-around",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    minWidth: "100%",
    ...shorthands.flex(1),
  },
  avatar: {
    width: "20px",
    height: "20px",
    ...shorthands.margin("0px", "5px", "0px", "0px"),
    ...shorthands.borderRadius("50%"),
  },
});

const LoadingThing = React.memo(({}) => {
  const styles = useStyles();
  return (
    <Card className={styles.card}>
      <CardPreview
        className={styles.grayBackground}
        style={{ display: "flex" }}
      >
        <SkeletonItem className={styles.skeletonImage} />
      </CardPreview>

      <CardHeader
        header={
          <div className={styles.loadingHeader}>
            <SkeletonItem className={styles.loadingText1} />
            <SkeletonItem className={styles.loadingText2} />
          </div>
        }
        action={
          <div>
            <SkeletonItem
              style={{
                width: "50px",
              }}
            />
          </div>
        }
      />
    </Card>
  );
});

const PreviewImageComponent = React.memo(
  ({ imgSource, agent, fallbackSource }) => {
    const styles = useStyles();
    const [imgLoading, setImgLoading] = React.useState(true);
    const [src, setSrc] = React.useState(imgSource);
    if (!imgSource) {
      return (
        <>
          <div className={styles.image1}>
            <ImageNotSupportedOutlined
              style={{
                color: teamsDarkTheme.colorNeutralForeground3,
                opacity: 0.5,
              }}
              size={25}
            />
          </div>
        </>
      );
    }

    return (
      <>
        <Image
          className={styles.image}
          alt={"3D model preview image"}
          src={agent === "printables" ? proxyUrl + src : src}
          onLoad={() => setImgLoading(false)}
          onError={() => {
            if (src !== fallbackSource) {
              setSrc(fallbackSource);
            } else {
              setImgLoading(false);
            }
          }}
          decoding="async"
          loading="eager"
        />
      </>
    );
  }
);

const CollectionCard = ({ collection, loading = false }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const [extraData, setExtraData] = React.useState(null);

  const handleClick = React.useCallback(() => {
    navigate(`/collections/collection?id=${collection.uid}`, {
      state: { normalCollection: { ...collection, ...extraData } },
    });
  }, [collection, extraData, navigate]);

  const fetchCollectionInfo = React.useCallback(async () => {
    setExtraData(null);
    if (!collection.uid) return;
    const promises = [
      fetchCreatorInfo(collection.userId, user),
      fetchPreviewThings(collection.uid),
      fetchNumItems(collection.uid),
      fetchCollectionTags(collection.uid),
    ];
    const [creatorInfo, previewThings, numItems, collectionTags] =
      await Promise.all(promises);
    setExtraData({ creatorInfo, previewThings, numItems, collectionTags });
  }, [collection, user]);

  const previewSourceObj = React.useMemo(() => {
    const getPreviewSource = (index, extraData) => {
      let normalThing = {};
      if (extraData?.previewThings?.[index]) {
        normalThing = modelNormalizer(extraData.previewThings[index]);
        return {
          uri: normalThing.goFinal_preview_image,
          agent: normalThing.goFinal_agent,
          fallback_uri: normalThing.goFinal_fallback_image,
        };
      }
      return {
        uri: "",
        agent: "",
        fallback_uri: "",
      };
    };

    const one = getPreviewSource(0, extraData);
    const two = getPreviewSource(1, extraData);
    const three = getPreviewSource(2, extraData);
    const four = getPreviewSource(3, extraData);

    return {
      0: { uri: one.uri, agent: one.agent, fallback_uri: one.fallback_uri },
      1: { uri: two.uri, agent: two.agent, fallback_uri: two.fallback_uri },
      2: {
        uri: three.uri,
        agent: three.agent,
        fallback_uri: three.fallback_uri,
      },
      3: { uri: four.uri, agent: four.agent, fallback_uri: four.fallback_uri },
    };
  }, [extraData]);

  const dateString = React.useMemo(() => {
    let finalStr;
    const createDateObj = (val) => {
      let date = new Date(val?.created_at);
      if (isNaN(date.getTime()) && val?.created_at?.seconds) {
        date = new Date(val.created_at.seconds * 1000); // Convert seconds to milliseconds
      }
      if (isNaN(date.getTime())) {
        date = new Date();
      }
      return date;
    };

    finalStr = moment(createDateObj(collection)).fromNow();

    return finalStr;
  }, [collection]);

  const avatar = React.useMemo(() => {
    return createAvatar(thumbs, {
      seed: "Aneka",
      scale: 75,
      size: 20,
      backgroundColor: [teamsDarkTheme.colorNeutralBackground2.slice(1)],
      shapeColor: [teamsDarkTheme.colorBrandBackground.slice(1)],
      eyes: ["variant4W16"],
    }).toDataUriSync();
  }, []);

  React.useEffect(() => {
    fetchCollectionInfo();
  }, [fetchCollectionInfo]);

  if (!Boolean(extraData) || loading) return <LoadingThing />;
  return (
    <div
      className={styles.card}
      onClick={handleClick}
      style={{
        display: "flex",
      }}
    >
      <div className={styles.grayBackground1} style={{ display: "flex" }}>
        <div className={styles.imageWrap}>
          <PreviewImageComponent
            imgSource={previewSourceObj[0].uri}
            agent={previewSourceObj[0].agent}
            fallbackSource={previewSourceObj[0].fallback_uri}
          />
          <PreviewImageComponent
            imgSource={previewSourceObj[1].uri}
            agent={previewSourceObj[1].agent}
            fallbackSource={previewSourceObj[1].fallback_uri}
          />
          <PreviewImageComponent
            imgSource={previewSourceObj[2].uri}
            agent={previewSourceObj[2].agent}
            fallbackSource={previewSourceObj[2].fallback_uri}
          />
          <PreviewImageComponent
            imgSource={previewSourceObj[3].uri}
            agent={previewSourceObj[3].agent}
            fallbackSource={previewSourceObj[3].fallback_uri}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px 15px",
          backgroundColor: teamsDarkTheme.colorNeutralBackground2,
        }}
      >
        {/* header */}
        <Text
          weight="semibold"
          wrap={true}
          ellipsis
          truncate
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: "1.5rem",
            maxHeight: "1.5rem",
          }}
        >
          {collection.title}
        </Text>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* description */}
          <div
            className={styles.caption}
            wrap={true}
            style={{
              overflow: "hidden",
              lineHeight: "1.5rem",
              maxHeight: "1.5rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {extraData?.creatorInfo?.photoURL ? (
                <Image
                  src={extraData?.creatorInfo?.photoURL}
                  className={styles.avatar}
                  fit="contain"
                  alt="3d-go-user-icon"
                  title="3D GO User Icon"
                />
              ) : (
                <Image
                  src={avatar}
                  className={styles.avatar}
                  alt="3d-go-user-icon"
                  title="3D GO User Icon"
                />
              )}
              <div
                style={{
                  lineHeight: "1.5rem",
                  maxHeight: "1.5rem",
                }}
              >
                {extraData?.creatorInfo?.displayName || "3D GO User"}
                {/* {" - "}
                {dateString} */}
              </div>
            </div>
          </div>
          {/* action */}
          <div>
            <div
              style={{
                flex: 1,
                flexDirection: "row",
                flexWrap: "nowrap",
                minWidth: "max-content",
                gap: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {extraData?.numItems}
              <FaCube />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CollectionCard);
