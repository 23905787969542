import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./items/user";
import likesReducer from "./items/likes";
import thingsReducer from "./items/things";

export default configureStore({
  reducer: {
    user: userReducer,
    likes: likesReducer,
    things: thingsReducer,
  },
});
