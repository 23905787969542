import React, { useCallback, useId, useMemo, useState } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  RadioGroup,
  Radio,
  Text,
  useToastController,
  Toast,
  ToastTitle,
  Toaster,
  Button,
  Spinner,
} from "@fluentui/react-components";
import { useWindowSize } from "@uidotdev/usehooks";
import Lottie from "lottie-react";
import AgentIcon from "../../Components/AgentIcon/AgentIcon";
import cube from "../../Components/logo-lottie1.json";
import { FaSortAmountDown } from "react-icons/fa";
import { Sheet } from "react-modal-sheet";
import { useDispatch, useSelector } from "react-redux";
import { BsFillTrashFill, BsPencil } from "react-icons/bs";
import { setCollectionThings, setCollections } from "../../store/items/things";
import { MdAdd } from "react-icons/md";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../hooks/useFirebase";
import EditCollectionModal from "./EditCollectionModal";
import { useNavigate } from "react-router-dom";
import { setShowModal as setShowAuthModal } from "../../store/items/user";

const SORT_OPTIONS = {
  newest: {
    label: "Newest",
  },
  oldest: {
    label: "Oldest",
  },
  aZ: {
    label: "A → Z",
  },
  zA: {
    label: "Z → A",
  },
};

const deleteCollection = async (collectionId) => {
  const promises = [];

  // Delete collection
  promises.push(deleteDoc(doc(db, "Collections", collectionId)));

  // Delete collection things
  const thingsQuery = query(
    collection(db, "CollectionThings"),
    where("collectionId", "==", collectionId)
  );
  const collectionThingsSnapshot = await getDocs(thingsQuery);

  collectionThingsSnapshot.forEach((docSnapshot) => {
    promises.push(deleteDoc(doc(db, "CollectionThings", docSnapshot.id)));
  });

  // Delete collection tags
  const tagsQuery = query(
    collection(db, "CollectionTagJoinTable"),
    where("collectionId", "==", collectionId)
  );
  const collectionTagsSnapshot = await getDocs(tagsQuery);

  collectionTagsSnapshot.forEach((docSnapshot) => {
    promises.push(deleteDoc(doc(db, "CollectionTagJoinTable", docSnapshot.id)));
  });

  await Promise.all(promises);
};

const cloneCollectionById = async (user, normalCollection) => {
  // Fetch existing things for the collection
  const thingsQuery = query(
    collection(db, "CollectionThings"),
    where("collectionId", "==", normalCollection.uid)
  );
  const thingsSnapshot = await getDocs(thingsQuery);
  const things = thingsSnapshot.docs.map((doc) => ({
    ...doc.data(),
    uid: doc.id,
  }));

  // Fetch existing tags for the collection
  const tagsQuery = query(
    collection(db, "CollectionTagJoinTable"),
    where("collectionId", "==", normalCollection.uid)
  );
  const tagsSnapshot = await getDocs(tagsQuery);
  const tags = tagsSnapshot.docs.map((doc) => ({ ...doc.data(), uid: doc.id }));

  // New collection object based on normalCollection
  const newCollection = {
    title: normalCollection.title,
    userId: user?.uid,
    isPrivate: false,
    created_at: new Date(),
  };

  // Add new collection
  const newCollectionRef = await addDoc(
    collection(db, "Collections"),
    newCollection
  );

  const promises = [];
  const newCollectionThings = [];

  // Clone each "thing"
  things.forEach((thing) => {
    const likeObj = {
      ...thing,
      userId: user?.uid,
      supplier: thing.goFinal_agent, // Adjust according to your data structure
      date_saved_3dgo: new Date(),
      collectionId: newCollectionRef.id,
    };

    // Remove the local UID since we're creating a new document
    delete likeObj.uid;

    const promise = addDoc(collection(db, "CollectionThings"), likeObj).then(
      (newThingRef) => {
        newCollectionThings.push({ ...likeObj, uid: newThingRef.id });
      }
    );

    promises.push(promise);
  });

  // Clone each tag, removing the local UID as it's a new document
  tags.forEach((tag) => {
    const { uid, ...tagWithoutUid } = tag; // Remove uid from tag object

    const promise = addDoc(collection(db, "CollectionTagJoinTable"), {
      ...tagWithoutUid,
      collectionId: newCollectionRef.id,
    });

    promises.push(promise);
  });

  await Promise.all(promises);
  return {
    newCollection: { ...newCollection, uid: newCollectionRef.id },
    newCollectionThings,
  };
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    height: "100%",
    ...shorthands.flex(1),
    maxWidth: "250px",
    boxSizing: "border-box",
    ...shorthands.borderRight(
      "1px",
      "solid",
      teamsDarkTheme.colorNeutralStroke2
    ),
    ...shorthands.borderLeft(
      "1px",
      "solid",
      teamsDarkTheme.colorNeutralStroke2
    ),
    alignItems: "flex-start",
    "@media (max-width: 850px)": {
      //   maxWidth: "100%",
      ...shorthands.flex(0),
      maxWidth: "100%",
      width: "100%",
      ...shorthands.borderRight("none"),
      ...shorthands.borderLeft("none"),
      ...shorthands.borderBottom(
        "1px",
        "solid",
        teamsDarkTheme.colorNeutralStroke2
      ),
    },
  },
  mainDrawer: {
    display: "flex",
    flexDirection: "column",
    position: "sticky",
    top: "0px",
    ...shorthands.flex(1),
    ...shorthands.gap("10px"),
    ...shorthands.padding("20px", "0px"),
  },
  brandWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...shorthands.padding("10px", "0px"),
    ...shorthands.flex(1),
  },
  agentWrap: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("5px"),
  },
  brandText: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  optionsWrap: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("10px"),
  },
  accordian: {
    backgroundColor: "red",
  },
  agentH: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  catH: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap("5px"),
  },
  accordionBody: {
    backgroundColor: teamsDarkTheme.colorNeutralBackground3,
    ...shorthands.padding("10px"),
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("5px"),
    ...shorthands.borderRadius("5px"),
    boxShadow: `0px 0px 2px 0px ${teamsDarkTheme.colorNeutralStroke1}`,
  },
  mobileDrawer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    ...shorthands.flex(1),
    ...shorthands.padding("10px"),
    ...shorthands.gap("10px"),
    backgroundColor: teamsDarkTheme.colorNeutralBackground3,
  },
  mobileOptionsWrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    ...shorthands.gap("10px"),
    ...shorthands.padding("10px", "15px"),
    ...shorthands.borderRadius("15px"),
    ...shorthands.border("1px", "solid", teamsDarkTheme.colorNeutralStroke2),
    backgroundColor: teamsDarkTheme.colorNeutralBackground1,
    "@media (max-width: 400px)": {
      justifyContent: "center",
    },
  },
  mobileLeft: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap("5px"),
  },
  mobileAgentH: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

function FilterSort({
  sort,
  setSort,
  normalCollection,
  fetchNormalCollection,
}) {
  const styles = useStyles();
  const windowSize = useWindowSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const lottieRef = React.useRef(null);
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const user = useSelector((state) => state.user.value);

  const userCollections = useSelector((state) => state.things.collections);
  const userCollectionThings = useSelector(
    (state) => state.things.collectionThings
  );

  const [showEditModal, setShowEditModal] = useState(false);
  const [isDeleteing, setIsDeleting] = useState(false);
  const [cloning, setCloning] = useState(false);

  const agentIconStyles = {
    width: "30px",
    height: "30px",
    borderRadius: "7px",
  };

  const lottieStyle = {
    width: `40px`,
    height: "40px",
    marginRight: "10px",
    borderRadius: "5px",
  };

  const handleDeleteCollection = useCallback(async () => {
    setIsDeleting(true);
    await deleteCollection(normalCollection.uid);
    dispatch(
      setCollections(
        userCollections.filter((c) => c.uid !== normalCollection.uid)
      )
    );
    dispatch(
      setCollectionThings(
        userCollectionThings.filter(
          (c) => c.collectionId !== normalCollection.uid
        )
      )
    );
    navigate("/collections/me");
    setIsDeleting(false);
  }, [
    dispatch,
    navigate,
    normalCollection,
    userCollectionThings,
    userCollections,
  ]);

  const handleCloneCollection = useCallback(async () => {
    if (!user) {
      dispatch(setShowAuthModal(true));
      return;
    }
    setCloning(true);
    try {
      const { newCollection, newCollectionThings } = await cloneCollectionById(
        user,
        normalCollection
      );
      dispatch(setCollections([newCollection, ...userCollections]));
      dispatch(
        setCollectionThings([...newCollectionThings, ...userCollectionThings])
      );
      dispatchToast(
        <Toast>
          <ToastTitle>Collection Cloned</ToastTitle>
        </Toast>,
        { position: "bottom", intent: "success" }
      );
    } catch (error) {
      console.error("Error cloning collection:", error);
    } finally {
      setCloning(false);
    }
  }, [
    user,
    normalCollection,
    dispatch,
    userCollections,
    userCollectionThings,
    dispatchToast,
  ]);

  const AccordionComponent = useMemo(() => {
    return (
      <Accordion
        collapsible
        multiple
        defaultOpenItems={["1"]}
        style={{ color: "white" }}
      >
        <AccordionItem value="1">
          <AccordionHeader>Sorting</AccordionHeader>
          <AccordionPanel className={styles.accordionBody}>
            <RadioGroup
              value={sort}
              onChange={(_, data) => setSort(data.value)}
            >
              <Radio value="newest" label="Newest" />
              <Radio value="oldest" label="Oldest" />
              <Radio value="aZ" label="A → Z" />
              <Radio value="zA" label="Z → A" />
            </RadioGroup>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  }, [setSort, sort, styles]);

  return (
    <>
      <div
        className={styles.root}
        style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground2 }}
      >
        {windowSize.width > 850 ? (
          <div className={styles.mainDrawer}>
            {/* heading info */}
            <div className={styles.brandWrap}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Lottie
                  animationData={cube}
                  lottieRef={lottieRef}
                  style={lottieStyle}
                />
                <p className={styles.brandText}>| 3D GO</p>
              </div>
              <div className={styles.agentWrap}>
                <AgentIcon agent="thingiverse" styles={agentIconStyles} />
                <AgentIcon agent="printables" styles={agentIconStyles} />
                <AgentIcon agent="cults3d" styles={agentIconStyles} />
                <AgentIcon agent="makerworld" styles={agentIconStyles} />
                <AgentIcon agent="myminifactory" styles={agentIconStyles} />
              </div>
            </div>
            <div
              style={{
                backgroundColor: teamsDarkTheme.colorNeutralBackground3,
              }}
            >
              <div
                className={styles.agentH}
                style={{
                  gap: "10px",
                  padding: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FaSortAmountDown
                  color={teamsDarkTheme.colorNeutralForeground3}
                />
                <Text
                  size={400}
                  weight="bold"
                  style={{
                    color: teamsDarkTheme.colorNeutralForeground3,
                  }}
                >
                  Filter & Sort
                </Text>
              </div>
            </div>
            <div className={styles.optionsWrap}>
              {AccordionComponent}
              <Button
                style={{ margin: "0px 12px" }}
                appearance="primary"
                onClick={handleCloneCollection}
                icon={
                  cloning ? (
                    <Spinner size="tiny" appearance="inverted" />
                  ) : (
                    <MdAdd size={15} />
                  )
                }
              >
                Clone Collection
              </Button>
              {user && user?.uid === normalCollection?.userId && (
                <Button
                  style={{ margin: "0px 12px" }}
                  onClick={() => setShowEditModal(true)}
                  icon={<BsPencil size={15} />}
                >
                  Edit Collection
                </Button>
              )}
              {user && user?.uid === normalCollection?.userId && (
                <Button
                  style={{ margin: "0px 12px" }}
                  onClick={handleDeleteCollection}
                  icon={
                    isDeleteing ? (
                      <Spinner size="tiny" appearance="inverted" />
                    ) : (
                      <BsFillTrashFill size={15} />
                    )
                  }
                >
                  Delete Collection
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.mobileDrawer}>
            <div
              className={styles.mobileOptionsWrap}
              onClick={() => setShowModal(true)}
            >
              <div className={styles.mobileLeft}>
                <FaSortAmountDown
                  color={teamsDarkTheme.colorNeutralForeground3}
                />
                {windowSize.width > 400 && <Text>Filter Settings</Text>}
              </div>
              <div className={styles.mobileLeft}>
                <Text>{SORT_OPTIONS[sort].label}</Text>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <Button
                appearance="primary"
                onClick={handleCloneCollection}
                icon={
                  cloning ? (
                    <Spinner size="tiny" appearance="inverted" />
                  ) : (
                    <MdAdd size={15} />
                  )
                }
              >
                Clone Collection
              </Button>
              {user && user?.uid === normalCollection?.userId && (
                <Button
                  onClick={() => setShowEditModal(true)}
                  icon={<BsPencil size={15} />}
                >
                  Edit
                </Button>
              )}
              {user && user?.uid === normalCollection?.userId && (
                <Button
                  onClick={handleDeleteCollection}
                  icon={
                    isDeleteing ? (
                      <Spinner size="tiny" appearance="inverted" />
                    ) : (
                      <BsFillTrashFill size={15} />
                    )
                  }
                >
                  {/* Delete */}
                </Button>
              )}
            </div>
            <Sheet
              isOpen={showModal}
              onClose={() => setShowModal(false)}
              snapPoints={[500]}
              mountPoint={document.getElementById("modelsPageRef")}
            >
              <Sheet.Container
                style={{
                  backgroundColor: teamsDarkTheme.colorNeutralBackground2,
                  borderRadius: "25px 25px 0px 0px",
                  border: `1px solid ${teamsDarkTheme.colorNeutralStroke2}`,
                  boxSizing: "border-box",
                  padding: "20px",
                  display: "flex",
                }}
              >
                <Sheet.Header
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "50px",
                      height: "2px",
                      backgroundColor: teamsDarkTheme.colorNeutralStroke2,
                    }}
                  />
                </Sheet.Header>
                <Sheet.Content
                  style={{
                    display: "flex",
                  }}
                >
                  <Sheet.Scroller
                    style={{
                      padding: "10px 0px",
                      scrollbarWidth: "none",
                    }}
                  >
                    {AccordionComponent}
                  </Sheet.Scroller>
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop
                onClick={() => setShowModal(false)}
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                }}
              />
            </Sheet>
          </div>
        )}
      </div>
      <div>
        <Toaster toasterId={toasterId} />
      </div>
      {user && user?.uid === normalCollection?.userId && (
        <EditCollectionModal
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          normalCollection={normalCollection}
          fetchNormalCollection={fetchNormalCollection}
        />
      )}
    </>
  );
}

export default React.memo(FilterSort);
