import "./MobileSlicer.css";
import { StlViewer } from "react-stl-viewer";
import { useEffect, useRef, useState } from "react";
import holderUrl from "../../holder.stl";
import BarLoader from "react-spinners/BarLoader";
import { HexColorPicker } from "react-colorful";
import { teamsDarkTheme } from "@fluentui/react-components";

// const proxyUrl =
//   "http://127.0.0.1:5001/thingiverse-explore/us-central1/proxyImage?agent=printables&url="; //DEV
const proxyUrl =
  "https://us-central1-thingiverse-explore.cloudfunctions.net/proxyImage?agent=printables&url=";

function MobileSlicer() {
  const style = {
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "black",
  };
  const url =
    "https://storage.googleapis.com/ucloud-v3/ccab50f18fb14c91ccca300a.stl";

  const url2 = "https://cdn.thingiverse.com/assets/1a/09/af/99/ed/t-pik09.STL";

  useEffect(() => {
    const handleMessageFromWebView = (event) => {
      // document.ReactNativeWebView.postMessage("received msg");
      try {
        JSON.parse(event.data);
      } catch (err) {
        console.log(err);
        return;
      }
      const message = JSON.parse(event.data); // The received message
      if (message.url || message.url === "") {
        try {
          const domain = new URL(message.url).hostname.toLowerCase();
          const isPrintables = domain.includes("printables");
          if (isPrintables) {
            setModelUrl(proxyUrl + message.url);
          } else {
            setModelUrl(message.url);
          }
        } catch (err) {
          setModelUrl(message.url);
        }
      } else if (message.base64) {
        handleBase64Message(message.base64);
      }
    };

    window.addEventListener("message", handleMessageFromWebView);
    return () => {
      window.removeEventListener("message", handleMessageFromWebView);
    };
  }, []);

  const modelRef = useRef(null);
  const [modelUrl, setModelUrl] = useState("");
  const [modelColor, setModelColor] = useState(
    teamsDarkTheme.colorBrandStroke1
  );
  const [loading, setLoading] = useState(true);
  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, [modelUrl]);

  const handleBase64Message = (base64String) => {
    setLoading(true);
    const binaryString = atob(base64String);
    const blobData = new Blob(
      [new Uint8Array([...binaryString].map((char) => char.charCodeAt(0)))],
      { type: "application/octet-stream" }
    );
    const url = URL.createObjectURL(blobData);
    setModelUrl(url);
  };

  return (
    <div className="App">
      <header className="App-header">
        {loading && (
          <div className="loaderWrap">
            <BarLoader color="white" loading size={100} />
          </div>
        )}
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            margin: "20px",
            zIndex: 100,
          }}
        >
          {showPicker ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <HexColorPicker
                color={modelColor}
                onChange={setModelColor}
                style={{ maxWidth: "50vw", maxHeight: "50vh" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "10px",
                  cursor: "pointer",
                }}
                onClick={() => setShowPicker(false)}
              >
                close
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "45px",
                height: "20px",
                backgroundColor: modelColor,
                borderRadius: 7,
                border: "solid lightgrey 2px",
                boxSizing: "border-box",
              }}
              onClick={() => setShowPicker(true)}
            />
          )}
        </div>
        <StlViewer
          style={style}
          orbitControls={true}
          shadows={true}
          modelProps={{
            ref: modelRef,
            color: modelColor,
            scale: 1,
            positionX: 250,
            positionY: 250,
            rotationX: 0,
            rotationY: 0,
            rotationZ: 0,
          }}
          showAxes={true}
          floorProps={{ gridLength: 500, gridWidth: 500 }}
          onFinishLoading={() => setLoading(false)}
          onError={(err) => {
            // window?.ReactNativeWebView?.postMessage(err);
            console.log(err);
          }}
          url={modelUrl === "" ? holderUrl : modelUrl}
        />
      </header>
    </div>
  );
}

export default MobileSlicer;
