import React, { useCallback, useMemo } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Button,
  Text,
  Divider,
  Image,
} from "@fluentui/react-components";
import { useLocation, useNavigate } from "react-router-dom";
import { modelNormalizer } from "../../utils/normalizers";
import qs from "qs";
import ThingCard from "../../Components/ThingCard/ThingCard";
import { FaCube } from "react-icons/fa";
import { BsFillCalendarFill, BsTagFill } from "react-icons/bs";
import { ChevronRight } from "@mui/icons-material";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../hooks/useFirebase";
import { createAvatar } from "@dicebear/core";
import { thumbs } from "@dicebear/collection";
import moment from "moment";
import { MdCollectionsBookmark } from "react-icons/md";
import { isMobile } from "react-device-detect";

const fetchCollectionTags = async (identifier) => {
  // Add orderBy clause for sorting by tagName in ascending order
  const q = query(
    collection(db, "CollectionTagJoinTable"),
    where("collectionId", "==", identifier)
  );
  const querySnapshot = await getDocs(q);
  const collectionTags = querySnapshot.docs.map((doc) => ({
    uid: doc.id,
    ...doc.data(),
  }));
  return collectionTags;
};

const fetchCollectionThings = async (identifier, params) => {
  const orderByField =
    params.sort === "newest" || params.sort === "oldest"
      ? "date_saved_3dgo"
      : "goFinal_name";
  const direction =
    params.sort === "newest" || params.sort === "aZ" ? "asc" : "desc";

  let q;
  // Initial query without a cursor
  q = query(
    collection(db, "CollectionThings"),
    where("collectionId", "==", identifier),
    orderBy(orderByField, direction)
  );

  const querySnapshot = await getDocs(q);

  const collectionThings = querySnapshot.docs.map((doc) => {
    const thingObj = {
      uid: doc.id,
      ...doc.data(),
    };
    const normalThing = modelNormalizer(thingObj, thingObj.goFinal_agent);
    return normalThing;
  });

  return collectionThings;
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    ...shorthands.padding("20px"),
    ...shorthands.gap("20px"),
    boxSizing: "border-box",
    flexGrow: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    alignItems: "center",
  },
  resultsWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    ...shorthands.gap("5px"),
    ...shorthands.flex(1),
  },
  tagContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    ...shorthands.gap("5px"),
    alignItems: "center",
  },
});

function ModelsComponent({ page, setPage, sort, normalCollection }) {
  const styles = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const collectionId = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true })?.id,
    [location.search]
  );

  const [things, setThings] = React.useState(new Array(24).fill(0));
  const [loading, setLoading] = React.useState(true);

  const loadModels = useCallback(async () => {
    setLoading(true);
    const params = {
      sort: sort,
    };
    const [collectionThings] = await Promise.all([
      fetchCollectionThings(collectionId, params),
    ]);
    Array.isArray(collectionThings) && setThings(collectionThings);
    setLoading(false);
  }, [sort, collectionId]);

  React.useEffect(() => {
    loadModels();
  }, [loadModels]);

  //   const extraPages = useMemo(() => {
  //     return things.length % 24 === 0 && things.length !== 0 ? 1 : 0;
  //   }, [things.length]);

  const avatar = useMemo(() => {
    return createAvatar(thumbs, {
      seed: "Aneka",
      scale: 75,
      size: 25,
      backgroundColor: [teamsDarkTheme.colorNeutralBackground2.slice(1)],
      shapeColor: [teamsDarkTheme.colorBrandBackground.slice(1)],
      eyes: ["variant4W16"],
    }).toDataUriSync();
  }, []);

  const dateString = useMemo(() => {
    let finalStr;
    const createDateObj = (val) => {
      let date = new Date(val?.created_at);
      if (isNaN(date.getTime()) && val?.created_at?.seconds) {
        date = new Date(val.created_at.seconds * 1000); // Convert seconds to milliseconds
      }
      if (isNaN(date.getTime())) {
        date = new Date();
      }
      return date;
    };

    finalStr = moment(createDateObj(normalCollection)).format("MMMM Do, YYYY");

    return finalStr;
  }, [normalCollection]);

  const handleClick = React.useCallback(
    (thing) => {
      const params = qs.stringify({
        agent: thing.goFinal_agent,
        id: thing.goFinal_shareId,
      });
      navigate(`/models/model?${params}`, { state: { thing } });
    },
    [navigate]
  );

  //   const handlePageChange = React.useCallback(
  //     (e, value) => {
  //       const modelsPageRef = document.getElementById("modelsPageRef");
  //       modelsPageRef.scrollTo({
  //         top: 0,
  //         behavior: "smooth", // Optional, smooth scrolling animation
  //       });
  //       setPage(value);
  //     },
  //     [setPage]
  //   );

  const methods = React.useMemo(() => {
    return {
      setSelectedThing: handleClick,
    };
  }, [handleClick]);

  const handleProfileNav = useCallback(() => {
    navigate(
      `/profiles?${qs.stringify({ id: normalCollection?.creatorInfo?.uid })}`,
      { state: { normalProfile: normalCollection?.creatorInfo } }
    );
  }, [navigate, normalCollection?.creatorInfo]);

  return (
    <div
      className={styles.root}
      style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: "5px",
            backgroundColor: teamsDarkTheme.colorNeutralBackground1,
            padding: "10px",
            borderRadius: "5px",
            border: `1px solid ${teamsDarkTheme.colorNeutralStroke1}`,
            // boxShadow: teamsDarkTheme.shadow2,
          }}
        >
          <Text
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
            weight="semibold"
            size={isMobile ? 600 : 800}
          >
            <MdCollectionsBookmark />
            {normalCollection?.title}
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <BsFillCalendarFill />
          <Text>{dateString}</Text>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            appearance="primary"
            icon={<ChevronRight />}
            iconPosition="after"
            onClick={handleProfileNav}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {normalCollection?.creatorInfo?.photoURL ? (
                <Image
                  src={normalCollection?.creatorInfo?.photoURL}
                  fit="contain"
                  style={{
                    borderRadius: 100,
                    width: 25,
                    height: 25,
                    border: `solid white 1px`,
                  }}
                />
              ) : (
                <Image
                  src={avatar}
                  style={{
                    borderRadius: 100,
                    border: `solid white 1px`,
                  }}
                />
              )}
              <Text weight="bold">
                {normalCollection?.creatorInfo?.displayName ??
                  "Awesome 3D GO User"}
              </Text>
            </div>
          </Button>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <Text
              weight="bold"
              style={{
                fontSize: 20,
              }}
            >
              {normalCollection?.numItems}
            </Text>
            <FaCube size={20} />
          </div>
        </div>
        {/* TAGS */}
        <Text weight="bold">Tags:</Text>
        <div className={styles.tagContainer}>
          {normalCollection?.collectionTags &&
            normalCollection.collectionTags.map((t) => {
              const handleClick = () => {
                navigate(`/search?q=tag:${t.tag_id}&type=collections`);
              };
              return (
                <Button icon={<BsTagFill />} onClick={handleClick}>
                  {t.tag_id}
                </Button>
              );
            })}
        </div>
      </div>
      <Divider
        style={{
          flex: 0,
        }}
      />
      <div className={styles.resultsWrap}>
        {things && things.length ? (
          <>
            {things.map((thing, i) => {
              const normalThing =
                thing !== 0 && modelNormalizer(thing, thing.blendSource);
              return (
                <ThingCard
                  thing={normalThing}
                  agent={thing.blendSource}
                  loading={loading}
                  methods={methods}
                  key={i}
                />
              );
            })}
          </>
        ) : (
          <div
            style={{
              flex: 1,
              flexGrow: 1,
            }}
          >
            No Results Found...
          </div>
        )}
      </div>
      {/* <Pagination
        variant="outlined"
        shape="rounded"
        page={page.value}
        count={page.value + extraPages}
        onChange={handlePageChange}
        sx={{
          ".MuiPaginationItem-root": {
            color: teamsDarkTheme.colorNeutralForeground1,
            borderColor: teamsDarkTheme.colorNeutralStroke1,
          },
          ".MuiPaginationItem-root:hover": {
            borderColor: teamsDarkTheme.colorBrandStroke1,
          },
          ".Mui-selected": {
            opacity: 0.5,
          },
        }}
      /> */}
    </div>
  );
}

export default React.memo(ModelsComponent);
