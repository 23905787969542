import {
  Button,
  Divider,
  Text,
  Title2,
  makeStyles,
  shorthands,
  teamsDarkTheme,
  Spinner,
} from "@fluentui/react-components";
import React from "react";
import AgentIcon from "../../AgentIcon/AgentIcon";
import { BookmarkAddRounded, PhoneIphone, Star } from "@mui/icons-material";
import { FaCubes } from "react-icons/fa";
import { useSelector } from "react-redux";
import { MdCollectionsBookmark } from "react-icons/md";
import Previewer from "./Previewer";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../hooks/useFirebase";

const useStyles = makeStyles({
  main: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...shorthands.gap("10px"),
    ...shorthands.flex(1),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...shorthands.flex(1),
    ...shorthands.gap("10px"),
    "> *": {
      textAlign: "center",
      fontWeight: "bold",
      border: "none",
    },
    "> div": {
      backgroundColor: teamsDarkTheme.colorNeutralBackground1,
      boxShadow: teamsDarkTheme.shadow2,
      ...shorthands.padding("5px", "15px"),
      ...shorthands.borderRadius("5px"),
    },
    "> div > *": {
      textAlign: "center",
      fontWeight: "bold",
      border: "none",
    },
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    ...shorthands.gap("5px"),
    "> p": {
      textAlign: "center",
      fontSize: "10px",
      backgroundColor: teamsDarkTheme.colorNeutralBackground3,
      ...shorthands.margin("0px"),
    },
  },
  footerButton: {
    boxShadow: teamsDarkTheme.shadow16,
    ...shorthands.borderRadius("15px"),
    ...shorthands.padding("10px", "5px"),
    ...shorthands.border("solid", "1px", teamsDarkTheme.colorNeutralStroke1),
    cursor: "pointer",
    zIndex: 100,
    width: "100%",
    ":hover": {
      ...shorthands.border("solid", "1px", "white"),
    },
  },
  agentIconWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.gap("10px"),
  },
  image: {
    width: "250px",
    ...shorthands.borderRadius("5px"),
    boxShadow: teamsDarkTheme.shadow2,
    // height: "250px",
    display: "flex",
    // ...shorthands.flex(1),
    // maxHeight: "250px",
  },
  statH: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    ...shorthands.gap("10px"),
  },
});

const PaywallTab = ({ swiperRef }) => {
  const styles = useStyles();
  const user = useSelector((state) => state?.user?.value);
  const [buttonLoading, setButtonLoading] = React.useState(false);

  const agentStyles = {
    width: "50px",
    height: "50px",
    borderRadius: "10px",
  };

  const handlePurchase = () => {
    logEvent(analytics, "paywallTab_Clicked");
    swiperRef.current.swiper.slideNext();
    return;
  };

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <div className={styles.statH}>
          <Title2>Welcome to 3D GO</Title2>
        </div>
        <Divider style={{ maxHeight: "1px", padding: "0px" }} />
        <div className={styles.agentIconWrap}>
          <AgentIcon agent="thingiverse" styles={agentStyles} />
          <AgentIcon agent="printables" styles={agentStyles} />
          <AgentIcon agent="cults3d" styles={agentStyles} />
          <AgentIcon agent="makerworld" styles={agentStyles} />
          <AgentIcon agent="myminifactory" styles={agentStyles} />
        </div>
        <div
          style={{
            position: "relative",
            backgroundColor: teamsDarkTheme.colorNeutralBackground1,
          }}
        >
          <Previewer />
        </div>
        <Divider style={{ maxHeight: "1px", padding: "0px" }} />
        <div className={styles.statH}>
          <Star />
          <Text>Browse All Top Providers In One Place</Text>
        </div>
        <div className={styles.statH}>
          <MdCollectionsBookmark size={18} />
          <Text>Create & Search Collections</Text>
        </div>
        <div className={styles.statH}>
          <PhoneIphone />
          <Text>Unlimited Mobile App Access</Text>
        </div>
        <div className={styles.statH}>
          <FaCubes size={20} />
          <Text>3D Preview + Slice Models</Text>
        </div>
        <div className={styles.statH}>
          <BookmarkAddRounded size={10} />
          <Text>Save Models / Support Developer</Text>
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          appearance="primary"
          className={styles.footerButton}
          onClick={handlePurchase}
          loading={buttonLoading}
        >
          {buttonLoading ? (
            <Spinner size="tiny" />
          ) : (
            "Continue With Free Access 🚀"
          )}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(PaywallTab);
