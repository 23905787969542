import { Button, makeStyles, shorthands } from "@fluentui/react-components";
import React, { useMemo } from "react";
import { BsTagFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    boxSizing: "border-box",
    ...shorthands.gap("5px"),
  },
  btn: {
    // ...shorthands.margin("5px 0px"),
  },
});

const TagsComponent = ({ thing }) => {
  const styles = useStyles();
  const navigate = useNavigate();

  const finalTags = useMemo(() => {
    return thing?.goFinal_tags && thing.goFinal_tags.length
      ? thing.goFinal_tags.slice(0, 20)
      : [];
  }, [thing]);

  return (
    <div className={styles.main}>
      {finalTags.map((tag, index) => {
        const handleClick = () => {
          navigate(`/search?q=tag:${tag}`);
        };
        return (
          <div key={index}>
            <Button
              onClick={handleClick}
              className={styles.btn}
              icon={<BsTagFill size={15} />}
            >
              {tag}
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(TagsComponent);
