import {
  Button,
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Text,
  Toast,
  ToastTitle,
  Spinner,
} from "@fluentui/react-components";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import ProfileSection from "./Components/ProfileSection";
import { analytics, auth } from "../../hooks/useFirebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { setRevenueCat, setUser } from "../../store/items/user";
import { captureException } from "@sentry/react";
import qs from "qs";
import { logEvent } from "firebase/analytics";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flex: 1,
    width: "100%",
    height: "100%",
    flexDirection: "column",
    boxSizing: "border-box",
    flexGrow: 1,
    ...shorthands.gap("5px"),
  },
  submitBtn: {
    boxShadow: teamsDarkTheme.shadow28,
    minHeight: "40px",
    ...shorthands.borderRadius("15px"),
    ...shorthands.border("solid", "1px", teamsDarkTheme.colorNeutralStroke1),
    ":hover": {
      ...shorthands.border("solid", "1px", "white"),
    },
  },
});

const CheckoutForm = ({ clientSecret, dispatchToast }) => {
  const stripe = useStripe();
  const elements = useElements();
  const styles = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authError, setAuthError] = useState(null);

  const [btnLoading, setBtnLoading] = useState(false);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    let uid = user?.uid;
    let finalEmail = user?.email || email;
    setBtnLoading(true);

    // If user is not logged in, create a new user w/ email and password
    if (!uid) {
      const userResult = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      ).catch((e) => {
        switch (e.code) {
          case "auth/missing-password":
            return { error: "Please enter a password" };
          case "auth/weak-password":
            return { error: "Password is too weak" };
          case "auth/invalid-email":
            return { error: "Invalid email" };
          case "auth/email-already-in-use":
            return { error: "Email already in use" };
          default:
            return { error: String(e.message) };
        }
      });

      if (userResult.error) {
        setBtnLoading(false);
        setAuthError(userResult.error);
        return;
      }
      logEvent(analytics, "paywall-auth-sign-in");
      const user = userResult.user;
      uid = user.uid;
      dispatch(setUser(user));
      setEmail("");
      setPassword("");
    }

    const test = await elements.submit();
    if (test.error) {
      setBtnLoading(false);
      return;
    }
    logEvent(analytics, "paywall-checkout-submitted");
    const redirectParams = qs.stringify({
      mode: "createStripeSubscription",
      email: finalEmail,
      uid,
    });

    const confirmResult = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: `https://3dgo.app/stripeWebhook?${redirectParams}`, // Your return URL after setup
        // return_url: `http://127.0.0.1:5001/thingiverse-explore/us-central1/stripeWebhook?${redirectParams}`, // Your return URL after setup
      },
    });

    if (confirmResult?.error || !confirmResult || !uid) {
      setBtnLoading(false);
      captureException(confirmResult, uid);
      window.alert("An error occured. Please try again.");
      window.location = "/models?show_onboarding=true&onboarding_slide=1";
      return;
    } else {
      // const submitUrl =
      //   "http://127.0.0.1:5001/thingiverse-explore/us-central1/createStripeSubscription";
      const submitUrl =
        "https://us-central1-thingiverse-explore.cloudfunctions.net/createStripeSubscription";
      const response = await fetch(submitUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: finalEmail, uid, confirmResult }),
      });

      setBtnLoading(false);
      const subscriptionData = await response.json();
      dispatch(setRevenueCat(subscriptionData?.revenueCat));

      // celebration toast
      dispatchToast(
        <Toast>
          <ToastTitle>Upgrade successful 🥳</ToastTitle>
        </Toast>,
        { intent: "success" }
      );
    }
  };

  return (
    <>
      <div className={styles.main}>
        <ProfileSection
          {...{ email, setEmail, password, setPassword, authError }}
        />
        <div
          style={{
            minHeight: "300px",
          }}
        >
          <PaymentElement />
        </div>
        <Button
          disabled={!stripe || btnLoading}
          onClick={handleSubmit}
          appearance="primary"
          className={styles.submitBtn}
        >
          {btnLoading ? (
            <Spinner size="tiny" appearance="inverted" />
          ) : (
            "Claim Your Free Trial 🥳"
          )}
        </Button>
        <Text
          as="p"
          style={{
            textAlign: "center",
            fontSize: "10px",
            margin: "0px",
            opacity: 0.7,
          }}
        >
          Free for 7 days then billed $2.49 / month or $29 annually
        </Text>
      </div>
    </>
  );
};

export default React.memo(CheckoutForm);
