import "./MobileSlicer.css";
import { useEffect, useState } from "react";
import holderUrl from "../../holder.stl";
import { HexColorPicker } from "react-colorful";
import ThreeViewer from "./ThreeViewer";
import { teamsDarkTheme } from "@fluentui/react-components";

// const proxyUrl =
//   "http://127.0.0.1:5001/thingiverse-explore/us-central1/proxyImage?agent=printables&url="; //DEV
const proxyUrl =
  "https://us-central1-thingiverse-explore.cloudfunctions.net/proxyImage?agent=printables&url=";

function NewMobilePreviewer() {
  useEffect(() => {
    const handleMessageFromWebView = (event) => {
      // document.ReactNativeWebView.postMessage("received msg");
      try {
        JSON.parse(event.data);
      } catch (err) {
        console.log(err);
        return;
      }
      const message = JSON.parse(event.data); // The received message
      if (message.url || message.url === "") {
        try {
          const domain = new URL(message.url).hostname.toLowerCase();
          const isPrintables = domain.includes("printables");
          if (isPrintables) {
            setModelUrl(proxyUrl + message.url);
          } else {
            setModelUrl(message.url);
          }
        } catch (err) {
          setModelUrl(message.url);
        }
      } else if (message.base64) {
        handleBase64Message(message.base64);
      }
    };

    window.addEventListener("message", handleMessageFromWebView);
    return () => {
      window.removeEventListener("message", handleMessageFromWebView);
    };
  }, []);

  const [modelUrl, setModelUrl] = useState("");
  const [modelColor, setModelColor] = useState(
    teamsDarkTheme.colorBrandStroke1
  );
  const [showPicker, setShowPicker] = useState(false);

  const handleBase64Message = (base64String) => {
    const binaryString = atob(base64String);
    const blobData = new Blob(
      [new Uint8Array([...binaryString].map((char) => char.charCodeAt(0)))],
      { type: "application/octet-stream" }
    );
    const url = URL.createObjectURL(blobData);
    setModelUrl(url);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            margin: "20px",
            zIndex: 100,
          }}
        >
          {showPicker ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <HexColorPicker
                color={modelColor}
                onChange={setModelColor}
                style={{ maxWidth: "50vw", maxHeight: "50vh" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "10px",
                  cursor: "pointer",
                }}
                onClick={() => setShowPicker(false)}
              >
                close
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "45px",
                height: "20px",
                backgroundColor: modelColor,
                borderRadius: 7,
                border: "solid lightgrey 2px",
                boxSizing: "border-box",
              }}
              onClick={() => setShowPicker(true)}
            />
          )}
        </div>
        <ThreeViewer
          url={modelUrl === "" ? holderUrl : modelUrl}
          baseColor={modelColor}
        />
      </header>
    </div>
  );
}

export default NewMobilePreviewer;
