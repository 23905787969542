import React from "react";
import { Image } from "@fluentui/react-components";
import thingiverse from "./th-logo.png";
import printables from "./printables-logo.png";
import cults3d from "./cults-logo.png";
import myminifactory from "./mmf-logo.jpeg";
import google from "./google-icon.png";
import apple from "./white-apple.png";
import makerworld from "./bambu.png";

const AgentIcon = ({
  agent,
  styles = { width: "20px", height: "20px", borderRadius: "3px" },
}) => {
  const srcObj = {
    thingiverse: thingiverse,
    printables: printables,
    cults3d: cults3d,
    myminifactory: myminifactory,
    google: google,
    apple: apple,
    makerworld: makerworld,
  };
  return (
    <Image
      src={srcObj[agent]}
      style={styles}
      alt={`${agent}-icon`}
      title={`${agent} icon`}
    />
  );
};

export default React.memo(AgentIcon);
