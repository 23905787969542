import {
  Button,
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Input,
  mergeClasses,
  Title3,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  Divider,
} from "@fluentui/react-components";
import { useMotion } from "@fluentui/react-motion-preview";
import React, { useCallback, useEffect, useId, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowModal, setUser } from "../../store/items/user";
import Lottie from "lottie-react";
import cube from "../logo-lottie1.json";
import {
  GoogleAuthProvider,
  OAuthProvider,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { MdLock, MdMail } from "react-icons/md";
import AgentIcon from "../AgentIcon/AgentIcon";
import { analytics, auth } from "../../hooks/useFirebase";
import { logEvent } from "firebase/analytics";

const useStyles = makeStyles({
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 150,
    backgroundColor: teamsDarkTheme.colorBackgroundOverlay,
    opacity: 0,
    transitionDuration: "150ms",
    transitionTimingFunction: "ease-out",
  },
  visibleOverlay: {
    opacity: 1,
  },
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    ...shorthands.padding("20px", "20px", "40px", "20px"),
    ...shorthands.gap("16px"),
    ...shorthands.borderRadius("5px"),
    backgroundColor: teamsDarkTheme.colorNeutralBackground2,
    minWidth: "400px",
    minHeight: "450px",

    transform: "translate3D(0, 0, 0) scale(0.75)",
    transitionDuration: "150ms",
    transitionTimingFunction: "ease-out",
  },
  visibleMain: {
    transform: "translate3D(0, 0, 0) scale(1)",
  },
  Input: {
    minWidth: "300px",
  },
  Button: {
    minWidth: "300px",
  },
  ContinueButton: {
    minWidth: "275px",
  },
  toggleText: {
    cursor: "pointer",
    transitionDuration: "150ms",
    ":hover": {
      opacity: 0.5,
    },
  },
});

const AuthModal = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const showModal = useSelector((state) => state.user.showModal);
  const handleClose = useCallback(() => {
    dispatch(setShowModal(false));
    setIsSignUp(true);
    setConfirmedPassword("");
    setPassword("");
    setEmail("");
  }, [dispatch]);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmedPassword, setConfirmedPassword] = React.useState("");
  const [isSignUp, setIsSignUp] = React.useState(true);

  const [forgotPasswordEmail, setForgotPasswordEmail] = React.useState("");

  const googleProvider = useMemo(() => new GoogleAuthProvider(), []);
  const appleProvider = useMemo(() => new OAuthProvider("apple.com"), []);
  appleProvider.addScope("email");
  appleProvider.addScope("name");

  const handleGoogleSignIn = useCallback(() => {
    logEvent(analytics, "user-sign-in");
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        const user = result.user;
        dispatch(setUser(user));
        dispatch(setShowModal(false));
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [dispatch, googleProvider]);

  const handleAppleSignIn = useCallback(() => {
    logEvent(analytics, "user-sign-in");
    signInWithPopup(auth, appleProvider)
      .then((result) => {
        const user = result.user;
        dispatch(setUser(user));
        dispatch(setShowModal(false));
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [appleProvider, dispatch]);

  const motion = useMotion(showModal);
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const notifyError = useCallback(
    (msg) => {
      dispatchToast(
        <Toast>
          <ToastTitle>{msg}</ToastTitle>
        </Toast>,
        { intent: "error" }
      );
    },
    [dispatchToast]
  );

  const basicChecks = useCallback((email, password) => {}, []); //TODO
  const handleManualAuth = useCallback(() => {
    logEvent(analytics, "user-sign-in");
    const failedCheck = basicChecks(email, password);
    const confirmedPasswordCheck = password === confirmedPassword;
    if (failedCheck) {
      notifyError(failedCheck);
      return;
    } else if (isSignUp && !confirmedPasswordCheck) {
      notifyError("Passwords do not match");
      return;
    }
    const authFunc = isSignUp
      ? createUserWithEmailAndPassword
      : signInWithEmailAndPassword;
    authFunc(auth, email, password)
      .then((result) => {
        const user = result.user;
        dispatch(setUser(user));
        dispatch(setShowModal(false));
        setEmail("");
        setPassword("");
        setConfirmedPassword("");
      })
      .catch((err) => {
        notifyError(err.message);
      });
  }, [
    basicChecks,
    confirmedPassword,
    dispatch,
    email,
    isSignUp,
    notifyError,
    password,
  ]);

  const handleForgotPassword = useCallback(async () => {
    const notifySuccess = (msg) => {
      dispatchToast(
        <Toast>
          <ToastTitle>{msg}</ToastTitle>
        </Toast>,
        { intent: "success" }
      );
    };

    try {
      await sendPasswordResetEmail(auth, forgotPasswordEmail).then(() => {
        notifySuccess("Password reset link has been sent.");
      });
    } catch (err) {
      notifyError(err.message);
    }
  }, [dispatchToast, forgotPasswordEmail, notifyError]);

  useEffect(() => {
    if (user) {
      handleClose();
    }
  }, [handleClose, user]);

  return (
    <>
      {motion.canRender && (
        <div
          ref={motion.ref}
          className={mergeClasses(
            styles.overlay,
            motion.active && styles.visibleOverlay
          )}
          onClick={handleClose}
        >
          <div
            ref={motion.ref}
            className={mergeClasses(
              styles.main,
              motion.active && styles.visibleMain
            )}
            onClick={(e) => e.stopPropagation()}
          >
            <Lottie
              animationData={cube}
              style={{
                width: `90px`,
                height: "90px",
              }}
            />
            <Title3>Log In or Sign Up</Title3>
            <Input
              className={styles.Input}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              type="email"
              contentBefore={<MdMail />}
            />
            <Input
              className={styles.Input}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              contentBefore={<MdLock />}
            />
            {isSignUp && (
              <Input
                className={styles.Input}
                value={confirmedPassword}
                onChange={(e) => setConfirmedPassword(e.target.value)}
                placeholder="Confirm Password"
                type="password"
              />
            )}
            <Button
              className={styles.Button}
              onClick={handleManualAuth}
              appearance="primary"
            >
              {isSignUp ? "Sign Up" : "Sign In"}
            </Button>
            <div>
              <Button
                onClick={() => setIsSignUp((v) => !v)}
                appearance="transparent"
              >
                {isSignUp ? "Already have an account?" : "Create New Account"}
              </Button>
              {!isSignUp && (
                <>
                  <Dialog
                    onOpenChange={(isOpen) => {
                      setForgotPasswordEmail("");
                    }}
                  >
                    <DialogTrigger disableButtonEnhancement>
                      <Button appearance="transparent">Forgot Password?</Button>
                    </DialogTrigger>
                    <DialogSurface
                      style={{
                        maxWidth: "350px",
                      }}
                    >
                      <DialogBody>
                        <DialogTitle>Password Reset Request</DialogTitle>
                        <DialogContent
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <Divider />
                          <div>
                            Type in your email below to receive a password reset
                            link.
                          </div>
                          <Input
                            style={{ flex: 1 }}
                            value={forgotPasswordEmail}
                            onChange={(e) =>
                              setForgotPasswordEmail(e.target.value)
                            }
                            placeholder="Email Address"
                            type="email"
                            contentBefore={<MdMail />}
                          />
                          <div
                            style={{
                              display: "flex",
                              gap: "8px",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            }}
                          >
                            <DialogTrigger disableButtonEnhancement>
                              <Button
                                appearance="secondary"
                                onClick={() => setForgotPasswordEmail("")}
                              >
                                Close
                              </Button>
                            </DialogTrigger>
                            <Button
                              appearance="primary"
                              onClick={handleForgotPassword}
                            >
                              Send Reset Link
                            </Button>
                          </div>
                        </DialogContent>
                      </DialogBody>
                    </DialogSurface>
                  </Dialog>
                </>
              )}
            </div>
            <Button
              onClick={handleGoogleSignIn}
              className={styles.ContinueButton}
              icon={<AgentIcon agent={"google"} />}
            >
              Continue with Google
            </Button>
            <Button
              onClick={handleAppleSignIn}
              className={styles.ContinueButton}
              icon={<AgentIcon agent={"apple"} />}
            >
              Continue with Apple
            </Button>
          </div>
        </div>
      )}
      <Toaster toasterId={toasterId} />
    </>
  );
};

export default React.memo(AuthModal);
