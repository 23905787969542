import React, { useCallback } from "react";
import {
  Divider,
  makeStyles,
  teamsDarkTheme,
  Title3,
  shorthands,
  Skeleton,
  SkeletonItem,
  Text,
  Button,
  Image,
  Dialog,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import createApiAgent from "../../utils/api-agent";
import { useNavigate } from "react-router-dom";
import { commentNormalizer } from "../../utils/normalizers";
import parse from "html-react-parser";
import { Close, CommentOutlined } from "@mui/icons-material";
import ImageViewer from "react-simple-image-viewer";
import { Pagination } from "@mui/material";

// const proxyUrl =
//   "http://127.0.0.1:5001/thingiverse-explore/us-central1/proxyImage?agent=printables&url="; //DEV
const proxyUrl =
  "https://us-central1-thingiverse-explore.cloudfunctions.net/proxyImage?agent=printables&url=";

const useStyles = makeStyles({
  modelCommentsWrap: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // maxWidth: "100vw",
    ...shorthands.padding("10px", "0px"),
    ...shorthands.gap("10px"),
    // boxSizing: "border-box",
  },
  commentHolder: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("5px"),
  },
  commentMain: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("10px"),
  },
  skeletonWrap: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("5px"),
  },
  skeletonHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    ...shorthands.gap("5px"),
  },
  skeletonImage: {
    width: "30px",
    height: "30px",
  },
  skeletonText: {
    width: "100%",
    height: "20px",
  },
  loadedCommentWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    ...shorthands.gap("10px"),
  },
  commentHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "flex-start",
    ...shorthands.gap("5px"),
  },
  commentBox: {
    display: "flex",
    width: "95%",
    ...shorthands.padding("5px", "5px"),
    ...shorthands.borderRadius("5px"),
    backgroundColor: teamsDarkTheme.colorNeutralBackground1Selected,
  },
  emptyWrap: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.padding("10px", "0px"),
    ...shorthands.borderRadius("5px"),
    backgroundColor: teamsDarkTheme.colorNeutralBackground1Selected,
  },
});

const CommentItem = ({ data, loading, agent, setShowImageViewer }) => {
  const styles = useStyles();
  const isPrintables = agent === "printables";

  if (loading) {
    return (
      <Skeleton className={styles.skeletonWrap}>
        <div className={styles.skeletonHeader}>
          <SkeletonItem className={styles.skeletonImage} />
          <SkeletonItem className={styles.skeletonText} />
        </div>
        <SkeletonItem className={styles.skeletonText} />
        <SkeletonItem className={styles.skeletonText} />
      </Skeleton>
    );
  } else {
    const normalComment = commentNormalizer(data, agent);
    if (!normalComment) return null;

    const userImage = () => {
      let url = normalComment.goFinal_imageUrl;
      return isPrintables ? proxyUrl + url : url;
    };

    return (
      <div className={styles.loadedCommentWrap}>
        <div className={styles.commentHeader}>
          <Image
            src={userImage()}
            style={{
              width: "25px",
              height: "25px",
              borderRadius: "100%",
            }}
          />
          <Text weight="bold">{normalComment.goFinal_creator.name}</Text>
        </div>
        {normalComment.goFinal_text && (
          <div className={styles.commentBox}>
            {parse(`<div>${normalComment.goFinal_text}</div>`)}
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "5px",
            width: "100%",
            paddingLeft: "10px",
            boxSizing: "border-box",
          }}
        >
          {normalComment.goFinal_images[0] && (
            <Image
              onClick={() =>
                setShowImageViewer({
                  images: normalComment.goFinal_images,
                  clicked: 0,
                })
              }
              src={normalComment.goFinal_images[0]}
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "5px",
                cursor: "pointer",
                objectFit: "cover",
              }}
            />
          )}
          {normalComment.goFinal_images[1] && (
            <Image
              onClick={() =>
                setShowImageViewer({
                  images: normalComment.goFinal_images,
                  clicked: 1,
                })
              }
              src={normalComment.goFinal_images[1]}
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "5px",
                cursor: "pointer",
                objectFit: "cover",
              }}
            />
          )}
          {normalComment.goFinal_images[2] && (
            <Image
              onClick={() =>
                setShowImageViewer({
                  images: normalComment.goFinal_images,
                  clicked: 2,
                })
              }
              src={normalComment.goFinal_images[2]}
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "5px",
                cursor: "pointer",
                objectFit: "cover",
              }}
            />
          )}
          {normalComment.goFinal_images.length > 3 && (
            <Text
              underline
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                setShowImageViewer({
                  images: normalComment.goFinal_images,
                  clicked: 3,
                })
              }
            >
              {normalComment.goFinal_images.length - 3} more
            </Text>
          )}
        </div>
      </div>
    );
  }
};

function ModelComments({ params, thing }) {
  const styles = useStyles();
  const navigate = useNavigate();
  const agent = thing.goFinal_agent;
  const thingIdentifier = thing.goFinal_shareId;
  const apiAgent = createApiAgent(agent);
  const [isLoading, setIsLoading] = React.useState(true);
  const [modalLoading, setModalLoading] = React.useState(true);
  const [comments, setComments] = React.useState(new Array(5).fill(0));
  const scrollRef = React.useRef(null);

  const [showModal, setShowModal] = React.useState(false);
  const [modelComments, setModelComments] = React.useState(
    new Array(5).fill(0)
  );
  const [page, setPage] = React.useState(1);
  const [showImageViewer, setShowImageViewer] = React.useState(false);

  const fetchInitialComments = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true);
    }
    const comments = await apiAgent.modelComments({
      id: thingIdentifier,
      page: 1,
      limit: 10,
    });
    setComments(comments);
    setModelComments(comments);
    setIsLoading(false);
    setModalLoading(false);
  }, [apiAgent, isLoading, thingIdentifier]);

  const fetchPageComments = useCallback(async () => {
    if (!showModal) return;
    if (!modalLoading) {
      setModalLoading(true);
    }
    const comments = await apiAgent.modelComments({
      id: thingIdentifier,
      page: page,
      limit: 10,
    });
    setModelComments(comments);
    setModalLoading(false);
  }, [apiAgent, modalLoading, page, showModal, thingIdentifier]);

  React.useEffect(() => {
    fetchInitialComments();
  }, []);

  React.useEffect(() => {
    fetchPageComments();
  }, [page]);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          scrollRef.current.scrollTop = 0; // Scroll to the top
          observer.disconnect(); // Stop observing after scrolling
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (scrollRef.current) {
      observer.observe(scrollRef.current);
    }

    return () => {
      observer.disconnect(); // Cleanup observer on component unmount
    };
  }, [showModal]);

  return (
    <>
      <div className={styles.modelCommentsWrap}>
        <div className={styles.commentHolder}>
          {comments.length ? (
            comments.slice(0, 3).map((comment, i) => {
              return (
                <div className={styles.commentMain}>
                  <CommentItem
                    data={comment}
                    loading={isLoading}
                    agent={agent}
                    setShowImageViewer={setShowImageViewer}
                  />
                  {i !== comments.length - 1 && i !== 2 && !isLoading && (
                    <Divider />
                  )}
                </div>
              );
            })
          ) : (
            <div className={styles.emptyWrap}>No Comments</div>
          )}
          {!isLoading && comments.length > 3 && (
            <Button
              onClick={() => {
                setShowModal(true);
              }}
              icon={<CommentOutlined fontSize={"small"} />}
            >
              View All Comments
            </Button>
          )}
        </div>
      </div>
      <Dialog
        open={showModal}
        onOpenChange={(data) => {
          setPage(1);
          setModelComments(comments);
          setShowModal(data.open);
        }}
      >
        <DialogSurface
          style={{
            backgroundColor: teamsDarkTheme.colorNeutralBackground3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "100",
            flexDirection: "column",
            height: "100%",
            overflowY: "scroll",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              gap: "10px",
            }}
          >
            <DialogTitle
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "5px",
                padding: "10px 10px",
                boxShadow: teamsDarkTheme.shadow2,
                backgroundColor: teamsDarkTheme.colorNeutralBackground1,
                flexShrink: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  flex: 1,
                }}
              >
                <CommentOutlined />
                <Text
                  style={{
                    fontSize: "20px",
                  }}
                  weight="bold"
                >
                  Comments
                </Text>
              </div>

              <Close
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowModal(false);
                  setModelComments(comments);
                  setPage(1);
                }}
              />
            </DialogTitle>
            <div
              id="scrollRef"
              ref={scrollRef}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                overflowY: "auto",
              }}
            >
              {showModal && (
                <>
                  {modelComments.map((comment, i) => {
                    return (
                      <div className={styles.commentMain}>
                        <CommentItem
                          setShowImageViewer={setShowImageViewer}
                          data={comment}
                          loading={modalLoading}
                          agent={agent}
                        />
                        {i !== modelComments.length - 1 && !modalLoading && (
                          <Divider />
                        )}
                      </div>
                    );
                  })}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Pagination
                      variant="outlined"
                      shape="rounded"
                      page={page}
                      count={
                        page +
                        (modelComments.length > 0 &&
                        modelComments.length % 10 === 0
                          ? 1
                          : 0)
                      }
                      onChange={(e, value) => {
                        const scrollRef = document.getElementById("scrollRef");
                        scrollRef.scrollTo({
                          top: 0,
                          behavior: "smooth", // Optional, smooth scrolling animation
                        });
                        setPage(value);
                      }}
                      sx={{
                        ".MuiPaginationItem-root": {
                          color: teamsDarkTheme.colorNeutralForeground1,
                          borderColor: teamsDarkTheme.colorNeutralStroke1,
                        },
                        ".MuiPaginationItem-root:hover": {
                          borderColor: teamsDarkTheme.colorBrandStroke1,
                        },
                        ".Mui-selected": {
                          opacity: 0.5,
                        },
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </DialogSurface>
      </Dialog>
      <Dialog
        open={showImageViewer}
        onOpenChange={(data) => {
          setShowImageViewer(false);
        }}
      >
        <DialogSurface
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "100vw",
            minHeight: "100vh",
          }}
        >
          {showImageViewer && (
            <ImageViewer
              src={showImageViewer.images}
              currentIndex={showImageViewer.clicked}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={() => setShowImageViewer(false)}
              style={{
                zIndex: "100000",
              }}
            />
          )}
        </DialogSurface>
      </Dialog>
    </>
  );
}

export default React.memo(ModelComments);
