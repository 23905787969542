import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Input,
  Spinner,
  Text,
  Toast,
  ToastTitle,
  Toaster,
  teamsDarkTheme,
  useId,
  useToastController,
} from "@fluentui/react-components";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCollections,
  setShowCreateCollectionModal,
} from "../../store/items/things";
import { MdAdd, MdClose, MdCollectionsBookmark } from "react-icons/md";
import { BsCheck } from "react-icons/bs";
import {
  RegExpMatcher,
  englishDataset,
  englishRecommendedTransformers,
} from "obscenity";
import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../hooks/useFirebase";
import { FaCrown } from "react-icons/fa";

const hasBadWord = (text) => {
  // check for profanity
  const matcher = new RegExpMatcher({
    ...englishDataset.build(),
    ...englishRecommendedTransformers,
  });

  if (matcher.hasMatch(text)) {
    return true;
  }

  return false;
};

const CreateCollectionModal = () => {
  const dispatch = useDispatch();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const open = useSelector((state) => state.things.showCreateCollectionModal);
  const user = useSelector((state) => state.user.value);
  const userCollections = useSelector((state) => state.things.collections);
  const revenueCat = useSelector((state) => state.user.revenueCat);

  const [nameText, setNameText] = useState("");
  const [tagInput, setTagInput] = useState("");
  const [tagsList, setTagsList] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);

  const [creatingCollection, setCreatingCollection] = useState(false);

  const onOpenChange = useCallback(
    (event, data) => {
      if (creatingCollection) {
        return;
      }
      setTagsList([]);
      setNameText("");
      setTagInput("");
      setIsPrivate(false);
      dispatch(setShowCreateCollectionModal(data.open));
    },
    [creatingCollection, dispatch]
  );

  const handleAddTag = useCallback(() => {
    if (!tagInput.length) return;
    const cleanTag = tagInput
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");
    if (!cleanTag) return;
    if (hasBadWord(cleanTag)) {
      dispatchToast(
        <Toast appearance="inverted">
          <ToastTitle>No bad words please</ToastTitle>
        </Toast>,
        { position: "bottom", intent: "warning" }
      );
      return;
    }
    if (tagsList.includes(cleanTag)) {
      dispatchToast(
        <Toast appearance="inverted">
          <ToastTitle>Tag already exists</ToastTitle>
        </Toast>,
        { position: "bottom", intent: "warning" }
      );
      return;
    }
    setTagsList((prev) => [...prev, cleanTag]);
    setTagInput("");
  }, [dispatchToast, tagInput, tagsList]);

  const handleCreateCollection = useCallback(async () => {
    if (!nameText || nameText.trim() === "") {
      dispatchToast(
        <Toast appearance="inverted">
          <ToastTitle>Name is required</ToastTitle>
        </Toast>,
        { position: "bottom", intent: "warning" }
      );
      return;
    } else if (hasBadWord(nameText)) {
      dispatchToast(
        <Toast appearance="inverted">
          <ToastTitle>No bad words please</ToastTitle>
        </Toast>,
        { position: "bottom", intent: "warning" }
      );
      return;
    } else if (tagsList.length === 0) {
      dispatchToast(
        <Toast appearance="inverted">
          <ToastTitle>At least 1 tag is required</ToastTitle>
        </Toast>,
        { position: "bottom", intent: "warning" }
      );
      return;
    }
    setIsPrivate(false);
    const newCollection = {
      title: nameText,
      userId: user?.uid,
      isPrivate: isPrivate,
      created_at: new Date(),
    };

    const newCollectionRef = await addDoc(
      collection(db, "Collections"),
      newCollection
    );

    newCollection.uid = newCollectionRef.id;

    const tagPromises = tagsList.map(async (tag) => {
      const tagDocRef = doc(db, "CollectionTags", tag);
      let tagSnap = await getDoc(tagDocRef);

      // If the tag does not exist, create it
      if (!tagSnap.exists()) {
        await setDoc(tagDocRef, {
          slug: tag,
          created_at: new Date(),
        });
      }

      await addDoc(collection(db, "CollectionTagJoinTable"), {
        collectionId: newCollectionRef.id,
        tag_id: tag, // Directly use 'tag' since it's equivalent to the document ID
        created_at: new Date(),
        title: nameText,
      });
    });

    await Promise.all(tagPromises);
    dispatch(setCollections([newCollection, ...userCollections]));
    dispatch(setShowCreateCollectionModal(null));
    setCreatingCollection(false);
    setTagsList([]);
    setNameText("");
    setTagInput("");
  }, [
    dispatch,
    dispatchToast,
    isPrivate,
    nameText,
    tagsList,
    user,
    userCollections,
  ]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogSurface
        style={{
          backgroundColor: teamsDarkTheme.colorNeutralBackground3,
          display: "flex",
          // full height for mobile here
        }}
      >
        <DialogBody>
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              borderRadius: "5px",
              padding: "10px 10px",
              boxShadow: teamsDarkTheme.shadow2,
              backgroundColor: teamsDarkTheme.colorNeutralBackground1,
              flexShrink: 1,
            }}
          >
            <MdCollectionsBookmark />
            <Text
              style={{
                fontSize: "20px",
              }}
              weight="bold"
            >
              Create New Collection
            </Text>
          </DialogTitle>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Text weight="bold">Name:</Text>
              <Input
                placeholder="Type Name..."
                onChange={(e, data) => setNameText(data.value)}
                value={nameText}
                style={{
                  backgroundColor: teamsDarkTheme.colorNeutralBackground1,
                  display: "flex",
                  flex: 1,
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Text weight="bold">Add Tags:</Text>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Input
                  //   appearance="underline"
                  placeholder="Type Tag..."
                  onChange={(e, data) => setTagInput(data.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleAddTag();
                    }
                  }}
                  value={tagInput}
                  style={{
                    backgroundColor: teamsDarkTheme.colorNeutralBackground1,
                    display: "flex",
                    flex: 1,
                  }}
                />
                <Button
                  appearance="primary"
                  onClick={handleAddTag}
                  icon={<MdAdd />}
                >
                  Add
                </Button>
              </div>
            </div>
            <Text weight="bold">Tags:</Text>
            <div
              id="tag-wrap"
              style={{
                display: "flex",
                width: "100%",
                minHeight: "100px",
                borderRadius: "5px",
                boxShadow: teamsDarkTheme.shadow2,
                padding: "10px",
                boxSizing: "border-box",
                flexWrap: "wrap",
                gap: "10px",
                backgroundColor: teamsDarkTheme.colorNeutralBackground1,
              }}
            >
              {tagsList.length ? (
                <>
                  {tagsList.map((tag) => {
                    return (
                      <Button
                        style={{
                          height: "min-content",
                          width: "mmin-content",
                        }}
                        icon={<MdClose />}
                        iconPosition="after"
                        onClick={() => {
                          setTagsList((v) => v.filter((t) => t !== tag));
                        }}
                      >
                        <Text>{tag}</Text>
                      </Button>
                    );
                  })}
                </>
              ) : (
                <Text style={{ opacity: 0.6 }}>
                  *At least 1 tag required...
                </Text>
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                opacity: !revenueCat?.entitlements?.pro ? 0.5 : 1,
              }}
            >
              <Checkbox
                checked={isPrivate}
                onChange={() => setIsPrivate((checked) => !checked)}
                disabled={!revenueCat?.entitlements?.pro}
              />
              <Text>Private Collection</Text>
              {!revenueCat?.entitlements?.pro && (
                <FaCrown style={{ paddingLeft: "5px" }} />
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Close</Button>
            </DialogTrigger>
            <Button
              appearance="primary"
              icon={
                creatingCollection ? (
                  <Spinner size="tiny" appearance="inverted" />
                ) : (
                  <BsCheck />
                )
              }
              onClick={handleCreateCollection}
            >
              Create Collection
            </Button>
          </DialogActions>
        </DialogBody>
        <Toaster toasterId={toasterId} />
      </DialogSurface>
    </Dialog>
  );
};

export default React.memo(CreateCollectionModal);
