import qs from "qs";

const queryString = (params) => qs.stringify(params);

const getHome = async (params) => {
  // const url =
  //   "http://127.0.0.1:5001/thingiverse-explore/us-central1/home?" +
  //   queryString(params);

  const url =
    "https://us-central1-thingiverse-explore.cloudfunctions.net/home?" +
    queryString(params);

  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await resp.json();
  } catch (e) {
    return { error: e };
  }
};

const getBlend = async (params) => {
  // const url =
  //   "http://127.0.0.1:5001/thingiverse-explore/us-central1/blend?" +
  //   queryString(params);

  const url =
    "https://us-central1-thingiverse-explore.cloudfunctions.net/blend?" +
    queryString(params);

  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await resp.json();
  } catch (e) {
    return { error: e };
  }
};

const getSuperAgent = async (params) => {
  // const url =
  //   "http://127.0.0.1:5001/thingiverse-explore/us-central1/superAgent?" +
  //   queryString(params);

  const url =
    "https://us-central1-thingiverse-explore.cloudfunctions.net/superAgent?" +
    queryString(params);

  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await resp.json();
  } catch (e) {
    return { error: e };
  }
};

const createApiAgent = (agent) => {
  const getCalls = {
    thingiverse: getSuperAgent,
    cults3d: getSuperAgent,
    myminifactory: getSuperAgent,
    printables: getSuperAgent,
    makerworld: getSuperAgent,
    home: getHome,
    blend: getBlend,
  };

  const megaAgent = {
    getExtraDetails: async (params) => {
      const queryParams = {
        ...params, // page
        mode: "getExtraDetails",
        superAgent: agent,
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    getPreviewFiles: async (params) => {
      const queryParams = {
        ...params, // page
        mode: "getPreviewFiles",
        superAgent: agent,
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    creatorThings: async (params) => {
      const queryParams = {
        ...params, // page & identifier
        mode: "creatorThings",
        superAgent: agent,
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    getCompetitions: async (params) => {
      const queryParams = {
        ...params, // page & identifier
        mode: "competitions",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    competitionDetails: async (params) => {
      const queryParams = {
        ...params, // page & identifier
        mode: "competitionDetails",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    popularModels: async (params) => {
      const queryParams = {
        ...params, // page & identifier
        mode: "popularModels",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    singleModel: async (params) => {
      const queryParams = {
        ...params,
        mode: "singleModel",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    modelComments: async (params) => {
      const queryParams = {
        ...params,
        mode: "modelComments",
        superAgent: agent,
      };
      agent === "myminifactory" && (queryParams.mmfEnabled = true); // needed for smooth mobile transition
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    relatedModels: async (params) => {
      const queryParams = {
        ...params,
        mode: "relatedModels",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    getModelsByCategory: async (params) => {
      const queryParams = {
        ...params,
        // category, limit, sorting, providers, page
        mode: "getModelsByCategory",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    searchModels: async (params) => {
      const queryParams = {
        ...params,
        // category, limit, sorting, providers, page
        mode: "searchModels",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    getContests: async (params) => {
      const queryParams = {
        ...params,
        // category, limit, sorting, providers, page
        mode: "getContests",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    contestDetails: async (params) => {
      const queryParams = {
        ...params,
        // category, limit, sorting, providers, page
        mode: "contestDetails",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
  };

  const agents = [
    "thingiverse",
    "cults3d",
    "myminifactory",
    "printables",
    "home",
    "blend",
    "makerworld",
  ];
  if (!agents.includes(agent)) {
    return null;
  }
  return megaAgent;
};

export default createApiAgent;
