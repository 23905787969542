import { createSlice } from "@reduxjs/toolkit";
//IAP / purchase info
export const likesSlice = createSlice({
  name: "device",
  initialState: {
    likesArr: [],
  },
  reducers: {
    addLike: (state, action) => {
      state.likesArr = [action.payload, ...state.likesArr];
    },
    removeLike: (state, action) => {
      state.likesArr = state.likesArr.filter(
        (like) => like.uid !== action.payload.uid
      ); // deep match
    },
    setLikes: (state, action) => {
      state.likesArr = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addLike, removeLike, setLikes } = likesSlice.actions;

export default likesSlice.reducer;
